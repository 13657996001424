/* src/app/Presentation/pages/encuesta/encuesta.component.scss */
input[type=radio] {
  width: 32px;
  height: 32px;
  border: 1px solid #b5b5b5;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 5px;
  color: white;
}
input[type=radio]:checked {
  background-image: url("./media/check-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #0572a1;
  color: white;
}
h3 {
  font-size: 1.2rem;
  color: #6a6a6a;
  width: 100%;
  font-weight: 400;
}
div {
  width: 100%;
  max-width: 900px;
  padding: 1rem;
  display: flex;
  align-items: center;
}
@media (max-width: 600px) {
  div {
    flex-direction: column;
  }
}
section {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100%;
}
div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox-select {
  display: flex;
  width: 5rem;
  flex-direction: column;
}
textarea {
  width: 100%;
  border: 2px solid #6a6a6a;
}
button {
  border: none;
  background-color: #262944;
  color: white;
  padding: 0.5rem;
  width: 8rem;
  font-weight: 800;
}
h2 {
  font-size: 2rem;
  font-weight: 800;
  color: #0572a1;
  max-width: 700px;
}
label {
  font-weight: 800;
  color: #0572a1;
}
@media (max-width: 900px) {
  h2 {
    font-size: 1.3rem;
  }
}
/*# sourceMappingURL=encuesta.component.css.map */
