export const questionsData = [
  {
    id: 42,
    value: '',
    question:
      'En una escala del 1 a 5, donde uno es muy malo y 5 muy bueno, la experiencia de uso del simulador',
  },
  {
    id: 43,
    value: '',
    question:
      'En una escala del 1 a 5, donde uno es muy malo y 5 muy util, la información arrojada por el simulador',
  },
  {
    id: 44,
    value: '',
    question:
      'En una escala del 1 a 5, donde 1 es muy dificil y 5 muy facil, la el acceso al simulador fue',
  },
  {
    id: 45,
    value: '',
    question:
      '¿Recomendaría el simulador a amigos o familiares que deseen formalizar su negocio?',
  },
  {
    id: 46,
    value: '',
    question:
      '¿En qué considera que podríamos mejorar para hacer un proceso más amigable y eficiente para nuestros usuarios? Déjenos un comentario en el recuadro.',
  },
];
