<app-navbar-bg-black/>
<h2>
  Responda las siguientes <br> preguntas con la <br> información del negocio
</h2>
<section class="container">
  <section class="ques-container">
      <div>
        <label for="patrimonio_bruto_anio_anterior" class="bruto">
          ¿Cuál fue su patrimonio bruto el año anterior?
          <img
            src="./assets/images/icon.svg"
            alt="?"
            [attr.data-bs-toggle]="'modal'"
            [attr.data-bs-target]="'#exampleModal-1'"
          />
      </label>
        <input
        role="textbox"
        tabindex="1"
          class="input"
          type="text"
          pattern="[0-9]"
          (keypress)="soloNumeros($event)"
          (input)="updateValue($event.target || '', 30)"
          (keydown)="filtrarNumero($event)"
          [value]="cantidad"
          (change)="updateValue($event.target || '', 30)"
          name="patrimonio_bruto_anio_anterior"
          id="patrimonio_bruto_anio_anterior"
          required
          autocomplete="off"
        />
      </div>
      <div>
        <label for="text" class="bruto">¿Cuál es su patrimonio bruto del presente año?
          <img
            src="./assets/images/icon.svg"
            alt="?"
            [attr.data-bs-toggle]="'modal'"
            [attr.data-bs-target]="'#exampleModal-2'"
          />
        </label>

        <input
        role="textbox"
        tabindex="2"
          class="input"
          type="text"
          pattern="[0-9]"
          (keypress)="soloNumeros($event)"
          (input)="updateValue($event.target || '', 31)"
          [value]="cantidadDos"
          (keydown)="filtrarNumero($event)"
          name="patrimonio_bruto_anio_presente"
          id="patrimonio_bruto_anio_presente"
          required
          autocomplete="off"
        />
      </div>
      <div class="span">
        <span>¿Cuáles fueron todos sus ingresos brutos del <b>año anterior</b> ?</span>
      </div>
      <div>
        <label for="actividades_gravadas_con_iva">¿Ingresos de actividades gravadas con IVA?</label
        ><input
        role="textbox"
        tabindex="3"
          class="input"
          type="text"
          pattern="[0-9]"
          (keypress)="soloNumeros($event)"
          (input)="updateValue($event.target || '', 32)"
          (change)="updateValue($event.target || '', 32)"
          [value]="cantidadTres"
          (keydown)="filtrarNumero($event)"
          id="actividades_gravadas_con_iva"
          name="actividades_gravadas_con_iva"
          required
          autocomplete="off"
        />
      </div>
      <div>
        <label for="actividades_restaurantes" class="large-ques"
          >Ingresos de actividades de restaurante, cafeterías, autoservicio,
          bares y similares</label
        ><input
        role="textbox"
        tabindex="4"
          class="input"
          type="text"
          pattern="[0-9]"
          (keypress)="soloNumeros($event)"
          (input)="updateValue($event.target || '', 33)"
          [value]="cantidadCuatro"
          (keydown)="filtrarNumero($event)"
          name="actividades_restaurantes"
          id="actividades_restaurantes"
          required
          autocomplete="off"
        />
      </div>
      <div>
        <label for="ingresos_laborales">Ingresos Laborales</label
        ><input
        role="textbox"
        tabindex="5"
          class="input"
          type="text"
          pattern="[0-9]"
          (keypress)="soloNumeros($event)"
          (input)="updateValue($event.target || '', 34)"
          [value]="cantidadCinco"
          (keydown)="filtrarNumero($event)"
          id="ingresos_laborales"
          name="ingresos_laborales"
          required
          autocomplete="off"
        />
      </div>
      <div>
        <label for="otros_ingresos_uno">Otros ingresos</label
        ><input
        role="textbox"
        tabindex="6"
          class="input"
          type="text"
          pattern="[0-9]"
          (keypress)="soloNumeros($event)"
          (input)="updateValue($event.target || '', 35)"
          [value]="cantidadSeis"
          (keydown)="filtrarNumero($event)"
          id="otros_ingresos_uno"
          name="otros_ingresos_uno"
          required
          autocomplete="off"
        />
      </div>
      <div>
        <label for="total_ingresos_uno">Total de ingresos brutos</label
        ><input
        role="textbox"
        tabindex="-1"
          class="input"
          type="text"
          (keydown)="(false)"
          [value]="cantidadSiete"
          id="total_ingresos_uno"
          name="total_ingresos_uno"
          required
          autocomplete="off"
        />
      </div>
      <div class="span">
        <span>¿Cuáles son sus ingresos brutos del <b>año en curso </b> ?</span>
      </div>
      <div>
        <label for="gravadas_con_iva_en_curso">¿Ingresos de actividades gravadas con IVA?</label
        ><input
        role="textbox"
        tabindex="8"
          type="text"
          pattern="[0-9]"
          (keypress)="soloNumeros($event)"
          class="input"
          (input)="updateValue($event.target || '', 37)"
          [value]="cantidadOcho"
          (keydown)="filtrarNumero($event)"
          id="gravadas_con_iva_en_curso"
          name="gravadas_con_iva_en_curso"
          required
          autocomplete="off"
        />
      </div>
      <div>
        <label for="actividades_restaurantes_en_curso"
          >Ingresos de actividades de restaurante, cafeterías, autoservicio,
          bares y similares</label
        ><input
        role="textbox"
        tabindex="9"
          type="text"
          pattern="[0-9]"
          (keypress)="soloNumeros($event)"
          class="input"
          (input)="updateValue($event.target || '', 38)"
          [value]="cantidadNueve"
          (keydown)="filtrarNumero($event)"
          id="actividades_restaurantes_en_curso"
          name="actividades_restaurantes_en_curso"
          required
          autocomplete="off"
        />
      </div>
      <div>
        <label for="ingresos_laborales_en_curso">Ingresos Laborales</label
        ><input
        role="textbox"
        tabindex="10"
          type="text"
          pattern="[0-9]"
          (keypress)="soloNumeros($event)"
          class="input"
          (input)="updateValue($event.target || '', 39)"
          [value]="cantidadDiez"
          (keydown)="filtrarNumero($event)"
          id="ingresos_laborales_en_curso"
          name="ingresos_laborales_en_curso"
          required
          autocomplete="off"
        />
      </div>
      <div>
        <label for="otros_ingresos_en_curso">Otros ingresos</label
        ><input
        role="textbox"
        tabindex="11"
          type="text"
          pattern="[0-9]"
          (keypress)="soloNumeros($event)"
          class="input"
          (input)="updateValue($event.target || '', 40)"
          [value]="cantidadOnce"
          (keydown)="filtrarNumero($event)"
          id="otros_ingresos_en_curso"
          name="otros_ingresos_en_curso"
          required
          autocomplete="off"
        />
      </div>
      <div>
        <label for="total_ingresos_dos">Total de ingresos brutos</label>
        <input
        role="textbox"
        tabindex="-1"
          type="text"
          class="input"
          (keydown)="(false)"
          id="total_ingresos_dos"
          name="total_ingresos_dos"
          [value]="cantidadDoce"
          autocomplete="off"
        />
      </div>

  </section>
  <section class="other-container">
    <img src="/assets/icons/recurso-11.png" alt="image" />
    <div class="footer-form">
      <button type="button" (click)="handleClick()" aria-label="Ver ruta de la <br> formalización" role="button" id="boton_continuar">
        Ver ruta de la <br> formalización
      </button>
      <app-loading [loading]="loading"/>

      <span>Recuerde que las respuestas son anónimas</span>
      @if(error){
        <div class="alert alert-danger" role="alert">
          {{error}}  Por favor haga <a href="/">clic aquí</a> para reiniciar el proceso y volver al inicio del aplicativo.
          <br>
          Cualquier inquietud puede comunicarse a la línea 57 (601) 307 8064 en el horario de atención: lunes a viernes 8:00 a. m. a 7:00 p. m. y sábados de 8:00 a. m. a 12:00 m.
        </div>
      }
    </div>
  </section>
</section>

<app-modal
[id]="'1'"
[modalTitle]="''"
[modalContent]="'Es la suma de todos los bienes y activos como inventarios, maquinarias y equipos, vehículos, casas, locales, dinero de cuentas, pagarés a su favor, entre otros, que su negocio tuvo al 31 del año pasado. A esa suma no le puede restar deudas.'"
/>

<app-modal
[id]="'2'"
[modalTitle]="''"
[modalContent]="'Es la suma de todos los bienes y activos como inventarios, maquinarias y equipos, vehículos, casas, locales, dinero de cuentas, pagarés a su favor, entre otros, que su negocio tiene actualmente. A esa suma no le puede restar deudas.'"
/>
