<div class="rating">

  <input value="1" [name]="name" [id]="id_1" type="radio" role="radio"  tabindex="1">
  <label title="text" [for]="id_1" (click)="getAnswer(5)" tabindex="1">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star" width="44" height="44" viewBox="0 0 24 24"
    stroke-width="1"
    stroke="#b5b5b5" fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
    </svg>
  </label>

  <input value="2" [name]="name" [id]="id_2" type="radio" (click)="getAnswer(4)" role="radio" tabindex="2">
  <label title="text" [for]="id_2">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star" width="44" height="44" viewBox="0 0 24 24"
    stroke-width="1"
    stroke="#b5b5b5" fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
    </svg>
  </label>

  <input value="3" [name]="name" [id]="id_3" type="radio" (click)="getAnswer(3)" role="radio" tabindex="3">
  <label title="text" [for]="id_3">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star" width="44" height="44" viewBox="0 0 24 24"
    stroke-width="1"
    stroke="#b5b5b5" fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
    </svg>
  </label>

  <input value="4" [name]="name" [id]="id_4" type="radio" (click)="getAnswer(2)" role="radio" tabindex="4">
  <label title="text" [for]="id_4">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star" width="44" height="44" viewBox="0 0 24 24"
    stroke-width="1"
    stroke="#b5b5b5" fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
    </svg>
  </label>

  <input value="5" [name]="name" [id]="id_5" type="radio" (click)="getAnswer(1)" role="radio" tabindex="5">
  <label title="text" [for]="id_5">
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star" width="44" height="44" viewBox="0 0 24 24"
    stroke-width="1"
    stroke="#b5b5b5" fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
    </svg>
  </label>
</div>
