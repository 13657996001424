/* src/app/Presentation/pages/form-siete/form-siete.component.scss */
section {
  background-image: url(/assets/images/bg-desktop_2.png);
  background-size: cover;
  height: auto;
}
.stepper-dos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 30rem;
}
.empty-space {
  height: 7rem;
  color: white;
  width: auto;
  padding: 1rem;
}
.info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8rem 0px 0px 10rem;
  max-width: 40rem;
}
.info-container h2 {
  font-weight: 800;
  color: #fff;
  font-size: 2rem;
  background-color: #272944;
}
.info-container p {
  font-size: 1.2rem;
  color: #02b0ef;
}
.step-name {
  font-size: 0.8rem;
}
.step-uno {
  width: 100%;
}
.step-uno h2 {
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: end;
  height: 7rem;
  color: white;
  width: 100%;
  padding: 1rem;
  border-right: 2px solid #02b0ef;
  border-bottom: 2px solid #02b0ef;
  background-color: #272944;
}
.step-uno .container-step {
  display: flex;
  padding: 1rem 0px;
}
.step-uno .stepper-item {
  border: none;
}
.stepper-container-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: white;
  font-size: 1rem;
  font-weight: 600;
}
.stepper-item .step-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #02b0ef;
}
.button {
  display: flex;
  justify-content: end;
  padding: 2rem 4rem;
  gap: 15rem;
  align-items: center;
}
.button .img1 {
  width: 15rem;
}
.button .img2 {
  cursor: pointer;
  background-color: white;
  width: 4rem;
  height: 5rem;
  border-radius: 0.6rem;
  border: 1px solid #272944;
}
.button div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.button div p {
  background-color: #272944;
  color: white;
}
.buttons-content {
  display: flex !important;
  justify-content: start;
  align-items: end;
  gap: 2rem;
}
.button button {
  background-color: #02b0ef;
  color: #fff;
  border: none;
  font-size: larger;
  font-weight: 800;
  width: 7rem;
  height: 3rem;
}
@media (max-width: 900px) {
  .info-container {
    padding-top: 20rem;
  }
  .container-step {
    flex-direction: column;
  }
  .stepper-dos {
    height: 100vh;
  }
  .info-container {
    width: 100%;
    font-size: 1.3rem;
    padding: 2rem 1rem 0px 1rem;
    background-color: #272944;
  }
  .step-uno h2 {
    font-size: 0.7rem;
    background-color: #272944;
  }
  .step-name {
    font-size: 0.6rem;
  }
  section {
    background-image: url(/assets/images/bg-mobile-2.png);
    background-size: cover;
  }
  .buttons-content {
    align-items: center;
    justify-content: center;
  }
  .button {
    flex-direction: column;
    gap: 1rem;
  }
  .button .img1 {
    width: 7rem;
  }
  .button .img2 {
    width: 3rem;
    height: 4rem;
  }
  .button div {
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 0.7rem;
    gap: 1rem;
  }
}
.alert,
.alert-primary {
  margin-bottom: 0px !important;
}
/*# sourceMappingURL=form-siete.component.css.map */
