/* src/app/Presentation/components/modal/modal.component.scss */
.modal-body {
  max-height: 15rem;
  overflow-y: auto;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  background-color: #ebf0f3;
}
.inputFilter {
  width: 100%;
  padding: 1rem;
  margin: auto;
}
.modal-header {
  border-bottom: none !important;
  background-color: #ebf0f3;
}
.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: none !important;
  background-color: #ebf0f3;
}
a {
  text-decoration: none;
}
.modal-footer button {
  background-color: transparent;
  border: none;
  color: #006591;
  font-size: 1.5rem;
  text-decoration-line: underline;
}
/*# sourceMappingURL=modal.component.css.map */
