export const municipiosV2: any = {
  Amazonas: [
    { id: 1, name: 'El Encanto' },
    { id: 2, name: 'La Chorrera' },
    { id: 3, name: 'La Pedrera' },
    { id: 4, name: 'La Victoria' },
    { id: 5, name: 'Leticia' },
    { id: 6, name: 'Miriti - Paraná' },
    { id: 7, name: 'Puerto Alegria' },
    { id: 8, name: 'Puerto Arica' },
    { id: 9, name: 'Puerto Nariño' },
    { id: 10, name: 'Puerto Santander' },
    { id: 11, name: 'Tarapacá' },
  ],
  Antioquia: [
    { id: 1, name: 'Cáceres' },
    { id: 2, name: 'Caucasia' },
    { id: 3, name: 'El Bagre' },
    { id: 4, name: 'Nechí' },
    { id: 5, name: 'Tarazá' },
    { id: 6, name: 'Zaragoza' },
    { id: 7, name: 'Caracolí' },
    { id: 8, name: 'Maceo' },
    { id: 9, name: 'Puerto Berrio' },
    { id: 10, name: 'Puerto Nare' },
    { id: 11, name: 'Puerto Triunfo' },
    { id: 12, name: 'Yondó' },
    { id: 13, name: 'Amalfi' },
    { id: 14, name: 'Anorí' },
    { id: 15, name: 'Cisneros' },
    { id: 16, name: 'Remedios' },
    { id: 17, name: 'San Roque' },
    { id: 18, name: 'Santo Domingo' },
    { id: 19, name: 'Segovia' },
    { id: 20, name: 'Vegachí' },
    { id: 21, name: 'Yalí' },
    { id: 22, name: 'Yolombó' },
    { id: 23, name: 'Angostura' },
    { id: 24, name: 'Belmira' },
    { id: 25, name: 'Briceño' },
    { id: 26, name: 'Campamento' },
    { id: 27, name: 'Carolina' },
    { id: 28, name: 'Don Matías' },
    { id: 29, name: 'Entrerrios' },
    { id: 30, name: 'Gómez Plata' },
    { id: 31, name: 'Guadalupe' },
    { id: 32, name: 'Ituango' },
    { id: 33, name: 'San Andrés' },
    { id: 34, name: 'San José De La Montaña' },
    { id: 35, name: 'San Pedro' },
    { id: 36, name: 'Santa Rosa De Osos' },
    { id: 37, name: 'Toledo' },
    { id: 38, name: 'Valdivia' },
    { id: 39, name: 'Yarumal' },
    { id: 40, name: 'Abriaquí' },
    { id: 41, name: 'Anzá' },
    { id: 42, name: 'Armenia' },
    { id: 43, name: 'Buriticá' },
    { id: 44, name: 'Cañasgordas' },
    { id: 45, name: 'Dabeiba' },
    { id: 46, name: 'Ebéjico' },
    { id: 47, name: 'Frontino' },
    { id: 48, name: 'Giraldo' },
    { id: 49, name: 'Heliconia' },
    { id: 50, name: 'Liborina' },
    { id: 51, name: 'Olaya' },
    { id: 52, name: 'Peque' },
    { id: 53, name: 'Sabanalarga' },
    { id: 54, name: 'San Jerónimo' },
    { id: 55, name: 'Santafé De Antioquia' },
    { id: 56, name: 'Sopetrán' },
    { id: 57, name: 'Uramita' },
    { id: 58, name: 'Abejorral' },
    { id: 59, name: 'Alejandría' },
    { id: 60, name: 'Carmen De Viboral' },
    { id: 61, name: 'Cocorná' },
    { id: 62, name: 'Concepción' },
    { id: 63, name: 'Granada' },
    { id: 64, name: 'Guarne' },
    { id: 65, name: 'Guatape' },
    { id: 66, name: 'La Ceja' },
    { id: 67, name: 'La Unión' },
    { id: 68, name: 'Marinilla' },
    { id: 69, name: 'Nariño' },
    { id: 70, name: 'Retiro' },
    { id: 71, name: 'San Carlos' },
    { id: 72, name: 'San Francisco' },
    { id: 73, name: 'San Luis' },
    { id: 74, name: 'San Rafael' },
    { id: 75, name: 'San Vicente' },
    { id: 76, name: 'Sonson' },
    { id: 77, name: 'Andes' },
    { id: 78, name: 'Angelopolis' },
    { id: 79, name: 'Betania' },
    { id: 80, name: 'Betulia' },
    { id: 81, name: 'Caicedo' },
    { id: 82, name: 'Caramanta' },
    { id: 83, name: 'Ciudad Bolívar' },
    { id: 84, name: 'Montebello' },
    { id: 85, name: 'Concordia' },
    { id: 86, name: 'Fredonia' },
    { id: 87, name: 'Hispania' },
    { id: 88, name: 'Jardín' },
    { id: 89, name: 'Jericó' },
    { id: 90, name: 'La Pintada' },
    { id: 91, name: 'Pueblorrico' },
    { id: 92, name: 'Salgar' },
    { id: 93, name: 'Santa Barbara' },
    { id: 94, name: 'Támesis' },
    { id: 95, name: 'Tarso' },
    { id: 96, name: 'Titiribí' },
    { id: 97, name: 'Urrao' },
    { id: 98, name: 'Valparaiso' },
    { id: 99, name: 'Venecia' },
    { id: 100, name: 'Apartadó' },
    { id: 101, name: 'Arboletes' },
    { id: 102, name: 'Carepa' },
    { id: 103, name: 'Chigorodó' },
    { id: 104, name: 'Murindó' },
    { id: 105, name: 'Mutatá' },
    { id: 106, name: 'Necoclí' },
    { id: 107, name: 'San Juan De Uraba' },
    { id: 108, name: 'San Pedro De Uraba' },
    { id: 109, name: 'Turbo' },
    { id: 110, name: 'Vigía Del Fuerte' },
    { id: 111, name: 'Barbosa' },
    { id: 112, name: 'Bello' },
    { id: 113, name: 'Copacabana' },
    { id: 114, name: 'Envigado' },
    { id: 115, name: 'Girardota' },
    { id: 116, name: 'Itagui' },
    { id: 117, name: 'La Estrella' },
    { id: 118, name: 'Medellín' },
    { id: 119, name: 'Sabaneta' },
  ],
  Arauca: [
    { id: 1, name: 'Arauca' },
    { id: 2, name: 'Arauquita' },
    { id: 3, name: 'Cravo Norte' },
    { id: 4, name: 'Fortul' },
    { id: 5, name: 'Puerto Rondón' },
    { id: 6, name: 'Saravena' },
    { id: 7, name: 'Tame' },
  ],
  'San Andrés y Providencia': [
    { id: 1, name: 'Providencia Y Santa Catalina' },
    { id: 2, name: 'San Andres' },
  ],
  Atlántico: [
    { id: 1, name: 'Barranquilla' },
    { id: 2, name: 'Galapa' },
    { id: 3, name: 'Malambo' },
    { id: 4, name: 'Puerto Colombia' },
    { id: 5, name: 'Soledad' },
    { id: 6, name: 'Campo De La Cruz' },
    { id: 7, name: 'Candelaria' },
    { id: 8, name: 'Luruaco' },
    { id: 9, name: 'Manati' },
    { id: 10, name: 'Repelon' },
    { id: 11, name: 'Santa Lucia' },
    { id: 12, name: 'Suan' },
    { id: 13, name: 'Baranoa' },
    { id: 14, name: 'Palmar De Varela' },
    { id: 15, name: 'Polonuevo' },
    { id: 16, name: 'Ponedera' },
    { id: 17, name: 'Sabanagrande' },
    { id: 18, name: 'Sabanalarga' },
    { id: 19, name: 'Santo Tomas' },
    { id: 20, name: 'Juan De Acosta' },
    { id: 21, name: 'Piojó' },
    { id: 22, name: 'Tubara' },
    { id: 23, name: 'Usiacuri' },
  ],
  Bogotá: [{ id: 1, name: 'Bogotá D.C.' }],
  Bolívar: [
    { id: 1, name: 'Cicuco' },
    { id: 2, name: 'Hatillo De Loba' },
    { id: 3, name: 'Margarita' },
    { id: 4, name: 'Mompós' },
    { id: 5, name: 'San Fernando' },
    { id: 6, name: 'Talaigua Nuevo' },
    { id: 7, name: 'Arjona' },
    { id: 8, name: 'Arroyohondo' },
    { id: 9, name: 'Calamar' },
    { id: 10, name: 'Cartagena' },
    { id: 11, name: 'Clemencia' },
    { id: 12, name: 'Mahates' },
    { id: 13, name: 'San Cristobal' },
    { id: 14, name: 'San Estanislao' },
    { id: 15, name: 'Santa Catalina' },
    { id: 16, name: 'Santa Rosa De Lima' },
    { id: 17, name: 'Soplaviento' },
    { id: 18, name: 'Turbaco' },
    { id: 19, name: 'Turbana' },
    { id: 20, name: 'Villanueva' },
    { id: 21, name: 'Altos Del Rosario' },
    { id: 22, name: 'Barranco De Loba' },
    { id: 23, name: 'El Peñon' },
    { id: 24, name: 'Regidor' },
    { id: 25, name: 'Río Viejo' },
    { id: 26, name: 'San Martin De Loba' },
    { id: 27, name: 'Arenal' },
    { id: 28, name: 'Cantagallo' },
    { id: 29, name: 'Morales' },
    { id: 30, name: 'San Pablo' },
    { id: 31, name: 'Santa Rosa Del Sur' },
    { id: 32, name: 'Simití' },
    { id: 33, name: 'Achí' },
    { id: 34, name: 'Magangué' },
    { id: 35, name: 'Montecristo' },
    { id: 36, name: 'Pinillos' },
    { id: 37, name: 'San Jacinto Del Cauca' },
    { id: 38, name: 'Tiquisio' },
    { id: 39, name: 'Carmen De Bolívar' },
    { id: 40, name: 'Córdoba' },
    { id: 41, name: 'El Guamo' },
    { id: 42, name: 'María La Baja' },
    { id: 43, name: 'San Jacinto' },
    { id: 44, name: 'San Juan Nepomuceno' },
    { id: 45, name: 'Zambrano' },
  ],
  Boyacá: [
    { id: 1, name: 'Chíquiza' },
    { id: 2, name: 'Chivatá' },
    { id: 3, name: 'Cómbita' },
    { id: 4, name: 'Cucaita' },
    { id: 5, name: 'Motavita' },
    { id: 6, name: 'Oicatá' },
    { id: 7, name: 'Samacá' },
    { id: 8, name: 'Siachoque' },
    { id: 9, name: 'Sora' },
    { id: 10, name: 'Soracá' },
    { id: 11, name: 'Sotaquirá' },
    { id: 12, name: 'Toca' },
    { id: 13, name: 'Tunja' },
    { id: 14, name: 'Tuta' },
    { id: 15, name: 'Ventaquemada' },
    { id: 16, name: 'Chiscas' },
    { id: 17, name: 'Cubará' },
    { id: 18, name: 'El Cocuy' },
    { id: 19, name: 'El Espino' },
    { id: 20, name: 'Guacamayas' },
    { id: 21, name: 'Güicán' },
    { id: 22, name: 'Panqueba' },
    { id: 23, name: 'Labranzagrande' },
    { id: 24, name: 'Pajarito' },
    { id: 25, name: 'Paya' },
    { id: 26, name: 'Pisba' },
    { id: 27, name: 'Berbeo' },
    { id: 28, name: 'Campohermoso' },
    { id: 29, name: 'Miraflores' },
    { id: 30, name: 'Páez' },
    { id: 31, name: 'San Eduardo' },
    { id: 32, name: 'Zetaquira' },
    { id: 33, name: 'Boyacá' },
    { id: 34, name: 'Ciénega' },
    { id: 35, name: 'Jenesano' },
    { id: 36, name: 'Nuevo Colón' },
    { id: 37, name: 'Ramiriquí' },
    { id: 38, name: 'Rondón' },
    { id: 39, name: 'Tibaná' },
    { id: 40, name: 'Turmequé' },
    { id: 41, name: 'Umbita' },
    { id: 42, name: 'Viracachá' },
    { id: 43, name: 'Chinavita' },
    { id: 44, name: 'Garagoa' },
    { id: 45, name: 'Macanal' },
    { id: 46, name: 'San Luis De Gaceno' },
    { id: 47, name: 'Santa María' },
    { id: 48, name: 'Boavita' },
    { id: 49, name: 'Covarachía' },
    { id: 50, name: 'La Uvita' },
    { id: 51, name: 'San Mateo' },
    { id: 52, name: 'Sativanorte' },
    { id: 53, name: 'Sativasur' },
    { id: 54, name: 'Soatá' },
    { id: 55, name: 'Susacón' },
    { id: 56, name: 'Tipacoque' },
    { id: 57, name: 'Briceño' },
    { id: 58, name: 'Buenavista' },
    { id: 59, name: 'Caldas' },
    { id: 60, name: 'Chiquinquirá' },
    { id: 61, name: 'Coper' },
    { id: 62, name: 'La Victoria' },
    { id: 63, name: 'Maripí' },
    { id: 64, name: 'Muzo' },
    { id: 65, name: 'Otanche' },
    { id: 66, name: 'Pauna' },
    { id: 67, name: 'Puerto Boyaca' },
    { id: 68, name: 'Quípama' },
    { id: 69, name: 'Saboyá' },
    { id: 70, name: 'San Miguel De Sema' },
    { id: 71, name: 'San Pablo Borbur' },
    { id: 72, name: 'Tununguá' },
    { id: 73, name: 'Almeida' },
    { id: 74, name: 'Chivor' },
    { id: 75, name: 'Guateque' },
    { id: 76, name: 'Guayatá' },
    { id: 77, name: 'La Capilla' },
    { id: 78, name: 'Somondoco' },
    { id: 79, name: 'Sutatenza' },
    { id: 80, name: 'Tenza' },
    { id: 81, name: 'Arcabuco' },
    { id: 82, name: 'Chitaraque' },
    { id: 83, name: 'Gachantivá' },
    { id: 84, name: 'Moniquirá' },
    { id: 85, name: 'Ráquira' },
    { id: 86, name: 'Sáchica' },
    { id: 87, name: 'San José De Pare' },
    { id: 88, name: 'Santa Sofía' },
    { id: 89, name: 'Santana' },
    { id: 90, name: 'Sutamarchán' },
    { id: 91, name: 'Tinjacá' },
    { id: 92, name: 'Togüí' },
    { id: 93, name: 'Villa De Leyva' },
    { id: 94, name: 'Aquitania' },
    { id: 95, name: 'Cuítiva' },
    { id: 96, name: 'Firavitoba' },
    { id: 97, name: 'Gameza' },
    { id: 98, name: 'Iza' },
    { id: 99, name: 'Mongua' },
    { id: 100, name: 'Monguí' },
    { id: 101, name: 'Nobsa' },
    { id: 102, name: 'Pesca' },
    { id: 103, name: 'Sogamoso' },
    { id: 104, name: 'Tibasosa' },
    { id: 105, name: 'Tópaga' },
    { id: 106, name: 'Tota' },
    { id: 107, name: 'Belén' },
    { id: 108, name: 'Busbanzá' },
    { id: 109, name: 'Cerinza' },
    { id: 110, name: 'Corrales' },
    { id: 111, name: 'Duitama' },
    { id: 112, name: 'Floresta' },
    { id: 113, name: 'Paipa' },
    { id: 114, name: 'San Rosa Viterbo' },
    { id: 115, name: 'Tutazá' },
    { id: 116, name: 'Betéitiva' },
    { id: 117, name: 'Chita' },
    { id: 118, name: 'Jericó' },
    { id: 119, name: 'Paz De Río' },
    { id: 120, name: 'Socha' },
    { id: 121, name: 'Socotá' },
    { id: 122, name: 'Tasco' },
  ],
  Caldas: [
    { id: 1, name: 'Filadelfia' },
    { id: 2, name: 'La Merced' },
    { id: 3, name: 'Marmato' },
    { id: 4, name: 'Riosucio' },
    { id: 5, name: 'Supía' },
    { id: 6, name: 'Manzanares' },
    { id: 7, name: 'Marquetalia' },
    { id: 8, name: 'Marulanda' },
    { id: 9, name: 'Pensilvania' },
    { id: 10, name: 'Anserma' },
    { id: 11, name: 'Belalcázar' },
    { id: 12, name: 'Risaralda' },
    { id: 13, name: 'San José' },
    { id: 14, name: 'Viterbo' },
    { id: 15, name: 'Chinchina' },
    { id: 16, name: 'Manizales' },
    { id: 17, name: 'Neira' },
    { id: 18, name: 'Palestina' },
    { id: 19, name: 'Villamaria' },
    { id: 20, name: 'Aguadas' },
    { id: 21, name: 'Aranzazu' },
    { id: 22, name: 'Pácora' },
    { id: 23, name: 'Salamina' },
    { id: 24, name: 'La Dorada' },
    { id: 25, name: 'Norcasia' },
    { id: 26, name: 'Samaná' },
    { id: 27, name: 'Victoria' },
  ],
  Caquetá: [
    { id: 1, name: 'Albania' },
    { id: 2, name: 'Belén De Los Andaquies' },
    { id: 3, name: 'Cartagena Del Chairá' },
    { id: 4, name: 'Currillo' },
    { id: 5, name: 'El Doncello' },
    { id: 6, name: 'El Paujil' },
    { id: 7, name: 'Florencia' },
    { id: 8, name: 'La Montañita' },
    { id: 9, name: 'Milan' },
    { id: 10, name: 'Morelia' },
    { id: 11, name: 'Puerto Rico' },
    { id: 12, name: 'San Jose Del Fragua' },
    { id: 13, name: 'San Vicente Del Caguán' },
    { id: 14, name: 'Solano' },
    { id: 15, name: 'Solita' },
    { id: 16, name: 'Valparaiso' },
  ],
  Casanare: [
    { id: 1, name: 'Aguazul' },
    { id: 2, name: 'Chameza' },
    { id: 3, name: 'Hato Corozal' },
    { id: 4, name: 'La Salina' },
    { id: 5, name: 'Maní' },
    { id: 6, name: 'Monterrey' },
    { id: 7, name: 'Nunchía' },
    { id: 8, name: 'Orocué' },
    { id: 9, name: 'Paz De Ariporo' },
    { id: 10, name: 'Pore' },
    { id: 11, name: 'Recetor' },
    { id: 12, name: 'Sabanalarga' },
    { id: 13, name: 'Sácama' },
    { id: 14, name: 'San Luis De Palenque' },
    { id: 15, name: 'Támara' },
    { id: 16, name: 'Tauramena' },
    { id: 17, name: 'Trinidad' },
    { id: 18, name: 'Villanueva' },
    { id: 19, name: 'Yopal' },
  ],
  Cauca: [
    { name: 'Cajibío' },
    { name: 'El Tambo' },
    { name: 'La Sierra' },
    { name: 'Morales' },
    { name: 'Piendamo' },
    { name: 'Popayán' },
    { name: 'Rosas' },
    { name: 'Sotara' },
    { name: 'Timbio' },
    { name: 'Buenos Aires' },
    { name: 'Caloto' },
    { name: 'Corinto' },
    { name: 'Miranda' },
    { name: 'Padilla' },
    { name: 'Puerto Tejada' },
    { name: 'Santander De Quilichao' },
    { name: 'Suarez' },
    { name: 'Villa Rica' },
    { name: 'Guapi' },
    { name: 'Lopez' },
    { name: 'Timbiqui' },
    { name: 'Caldono' },
    { name: 'Inzá' },
    { name: 'Jambalo' },
    { name: 'Paez' },
    { name: 'Purace' },
    { name: 'Silvia' },
    { name: 'Toribio' },
    { name: 'Totoro' },
    { name: 'Almaguer' },
    { name: 'Argelia' },
    { name: 'Balboa' },
    { name: 'Bolívar' },
    { name: 'Florencia' },
    { name: 'La Vega' },
    { name: 'Mercaderes' },
    { name: 'Patia' },
    { name: 'Piamonte' },
    { name: 'San Sebastian' },
    { name: 'Santa Rosa' },
    { name: 'Sucre' },
  ],
  Cesar: [
    { name: 'Becerril' },
    { name: 'Chimichagua' },
    { name: 'Chiriguana' },
    { name: 'Curumaní' },
    { name: 'La Jagua De Ibirico' },
    { name: 'Pailitas' },
    { name: 'Tamalameque' },
    { name: 'Astrea' },
    { name: 'Bosconia' },
    { name: 'El Copey' },
    { name: 'El Paso' },
    { name: 'Agustín Codazzi' },
    { name: 'La Paz' },
    { name: 'Manaure' },
    { name: 'Pueblo Bello' },
    { name: 'San Diego' },
    { name: 'Valledupar' },
    { name: 'Aguachica' },
    { name: 'Gamarra' },
    { name: 'González' },
    { name: 'La Gloria' },
    { name: 'Pelaya' },
    { name: 'Río De Oro' },
    { name: 'San Alberto' },
    { name: 'San Martín' },
  ],
  Chocó: [
    { name: 'Atrato' },
    { name: 'Bagadó' },
    { name: 'Bojaya' },
    { name: 'El Carmen De Atrato' },
    { name: 'Lloró' },
    { name: 'Medio Atrato' },
    { name: 'Quibdó' },
    { name: 'Rio Quito' },
    { name: 'Acandí' },
    { name: 'Belén De Bajira' },
    { name: 'Carmén Del Darién' },
    { name: 'Riosucio' },
    { name: 'Unguía' },
    { name: 'Bahía Solano' },
    { name: 'Juradó' },
    { name: 'Nuquí' },
    { name: 'Alto Baudó' },
    { name: 'Bajo Baudó' },
    { name: 'El Litoral Del San Juan' },
    { name: 'Medio Baudó' },
    { name: 'Canton De San Pablo' },
    { name: 'Certegui' },
    { name: 'Condoto' },
    { name: 'Itsmina' },
    { name: 'Medio San Juan' },
    { name: 'Nóvita' },
    { name: 'Río Frío' },
    { name: 'San José Del Palmar' },
    { name: 'Sipí' },
    { name: 'Tadó' },
    { name: 'Union Panamericana' },
  ],
  Córdoba: [
    { name: 'Tierralta' },
    { name: 'Valencia' },
    { name: 'Chimá' },
    { name: 'Cotorra' },
    { name: 'Lorica' },
    { name: 'Momil' },
    { name: 'Purísima' },
    { name: 'Montería' },
    { name: 'Canalete' },
    { name: 'Los Córdobas' },
    { name: 'Moñitos' },
    { name: 'Puerto Escondido' },
    { name: 'San Antero' },
    { name: 'San Bernardo Del Viento' },
    { name: 'Chinú' },
    { name: 'Sahagún' },
    { name: 'San Andrés Sotavento' },
    { name: 'Ayapel' },
    { name: 'Buenavista' },
    { name: 'La Apartada' },
    { name: 'Montelíbano' },
    { name: 'Planeta Rica' },
    { name: 'Pueblo Nuevo' },
    { name: 'Puerto Libertador' },
    { name: 'Cereté' },
    { name: 'Ciénaga De Oro' },
    { name: 'San Carlos' },
    { name: 'San Pelayo' },
  ],
  Cundinamarca: [
    { name: 'Chocontá' },
    { name: 'Macheta' },
    { name: 'Manta' },
    { name: 'Sesquilé' },
    { name: 'Suesca' },
    { name: 'Tibirita' },
    { name: 'Villapinzón' },
    { name: 'Agua De Dios' },
    { name: 'Girardot' },
    { name: 'Guataquí' },
    { name: 'Jerusalén' },
    { name: 'Nariño' },
    { name: 'Nilo' },
    { name: 'Ricaurte' },
    { name: 'Tocaima' },
    { name: 'Caparrapí' },
    { name: 'Guaduas' },
    { name: 'Puerto Salgar' },
    { name: 'Albán' },
    { name: 'La Peña' },
    { name: 'La Vega' },
    { name: 'Nimaima' },
    { name: 'Nocaima' },
    { name: 'Quebradanegra' },
    { name: 'San Francisco' },
    { name: 'Sasaima' },
    { name: 'Supatá' },
    { name: 'Útica' },
    { name: 'Vergara' },
    { name: 'Villeta' },
    { name: 'Gachala' },
    { name: 'Gacheta' },
    { name: 'Gama' },
    { name: 'Guasca' },
    { name: 'Guatavita' },
    { name: 'Junín' },
    { name: 'La Calera' },
    { name: 'Ubalá' },
    { name: 'Beltrán' },
    { name: 'Bituima' },
    { name: 'Chaguaní' },
    { name: 'Guayabal De Siquima' },
    { name: 'Puli' },
    { name: 'San Juan De Río Seco' },
    { name: 'Vianí' },
    { name: 'Medina' },
    { name: 'Paratebueno' },
    { name: 'Caqueza' },
    { name: 'Chipaque' },
    { name: 'Choachí' },
    { name: 'Fomeque' },
    { name: 'Fosca' },
    { name: 'Guayabetal' },
    { name: 'Gutiérrez' },
    { name: 'Quetame' },
    { name: 'Ubaque' },
    { name: 'Une' },
    { name: 'El Peñón' },
    { name: 'La Palma' },
    { name: 'Pacho' },
    { name: 'Paime' },
    { name: 'San Cayetano' },
    { name: 'Topaipi' },
    { name: 'Villagomez' },
    { name: 'Yacopí' },
    { name: 'Cajicá' },
    { name: 'Chía' },
    { name: 'Cogua' },
    { name: 'Gachancipá' },
    { name: 'Nemocon' },
    { name: 'Sopó' },
    { name: 'Tabio' },
    { name: 'Tocancipá' },
    { name: 'Zipaquirá' },
    { name: 'Bojacá' },
    { name: 'Cota' },
    { name: 'El Rosal' },
    { name: 'Facatativá' },
    { name: 'Funza' },
    { name: 'Madrid' },
    { name: 'Mosquera' },
    { name: 'Subachoque' },
    { name: 'Tenjo' },
    { name: 'Zipacon' },
    { name: 'Sibaté' },
    { name: 'Soacha' },
    { name: 'Arbeláez' },
    { name: 'Cabrera' },
    { name: 'Fusagasugá' },
    { name: 'Granada' },
    { name: 'Pandi' },
    { name: 'Pasca' },
    { name: 'San Bernardo' },
    { name: 'Silvania' },
    { name: 'Tibacuy' },
    { name: 'Venecia' },
    { name: 'Anapoima' },
    { name: 'Anolaima' },
    { name: 'Apulo' },
    { name: 'Cachipay' },
    { name: 'El Colegio' },
    { name: 'La Mesa' },
    { name: 'Quipile' },
    { name: 'San Antonio De Tequendama' },
    { name: 'Tena' },
    { name: 'Viotá' },
    { name: 'Carmen De Carupa' },
    { name: 'Cucunubá' },
    { name: 'Fúquene' },
    { name: 'Guachetá' },
    { name: 'Lenguazaque' },
    { name: 'Simijaca' },
    { name: 'Susa' },
    { name: 'Sutatausa' },
    { name: 'Tausa' },
    { name: 'Ubate' },
  ],
  Guainía: [
    { name: 'Barranco Mina' },
    { name: 'Cacahual' },
    { name: 'Inírida' },
    { name: 'La Guadalupe' },
    { name: 'Mapiripan' },
    { name: 'Morichal' },
    { name: 'Pana Pana' },
    { name: 'Puerto Colombia' },
    { name: 'San Felipe' },
  ],
  Guaviare: [
    { name: 'Calamar' },
    { name: 'El Retorno' },
    { name: 'Miraflores' },
    { name: 'San José Del Guaviare' },
  ],
  Huila: [
    { name: 'Agrado' },
    { name: 'Altamira' },
    { name: 'Garzón' },
    { name: 'Gigante' },
    { name: 'Guadalupe' },
    { name: 'Pital' },
    { name: 'Suaza' },
    { name: 'Tarqui' },
    { name: 'Aipe' },
    { name: 'Algeciras' },
    { name: 'Baraya' },
    { name: 'Campoalegre' },
    { name: 'Colombia' },
    { name: 'Hobo' },
    { name: 'Iquira' },
    { name: 'Neiva' },
    { name: 'Palermo' },
    { name: 'Rivera' },
    { name: 'Santa María' },
    { name: 'Tello' },
    { name: 'Teruel' },
    { name: 'Villavieja' },
    { name: 'Yaguará' },
    { name: 'La Argentina' },
    { name: 'La Plata' },
    { name: 'Nátaga' },
    { name: 'Paicol' },
    { name: 'Tesalia' },
    { name: 'Acevedo' },
    { name: 'Elías' },
    { name: 'Isnos' },
    { name: 'Oporapa' },
    { name: 'Palestina' },
    { name: 'Pitalito' },
    { name: 'Saladoblanco' },
    { name: 'San Agustín' },
    { name: 'Timaná' },
  ],
  'La Guajira': [
    { name: 'Albania' },
    { name: 'Dibulla' },
    { name: 'Maicao' },
    { name: 'Manaure' },
    { name: 'Riohacha' },
    { name: 'Uribia' },
    { name: 'Barrancas' },
    { name: 'Distraccion' },
    { name: 'El Molino' },
    { name: 'Fonseca' },
    { name: 'Hatonuevo' },
    { name: 'La Jagua Del Pilar' },
    { name: 'San Juan Del Cesar' },
    { name: 'Urumita' },
    { name: 'Villanueva' },
  ],
  Magdalena: [
    { name: 'Ariguaní' },
    { name: 'Chibolo' },
    { name: 'Nueva Granada' },
    { name: 'Plato' },
    { name: 'Sabanas De San Angel' },
    { name: 'Tenerife' },
    { name: 'Algarrobo' },
    { name: 'Aracataca' },
    { name: 'Ciénaga' },
    { name: 'El Reten' },
    { name: 'Fundacion' },
    { name: 'Pueblo Viejo' },
    { name: 'Zona Bananera' },
    { name: 'Cerro San Antonio' },
    { name: 'Concordia' },
    { name: 'El Piñon' },
    { name: 'Pedraza' },
    { name: 'Pivijay' },
    { name: 'Remolino' },
    { name: 'Salamina' },
    { name: 'Sitionuevo' },
    { name: 'Zapayan' },
    { name: 'Santa Marta' },
    { name: 'El Banco' },
    { name: 'Guamal' },
    { name: 'Pijiño Del Carmen' },
    { name: 'San Sebastian De Buenavista' },
    { name: 'San Zenon' },
    { name: 'Santa Ana' },
    { name: 'Santa Barbara De Pinto' },
  ],
  Meta: [
    { name: 'El Castillo' },
    { name: 'El Dorado' },
    { name: 'Fuente De Oro' },
    { name: 'Granada' },
    { name: 'La Macarena' },
    { name: 'La Uribe' },
    { name: 'Lejanías' },
    { name: 'Mapiripan' },
    { name: 'Mesetas' },
    { name: 'Puerto Concordia' },
    { name: 'Puerto Lleras' },
    { name: 'Puerto Rico' },
    { name: 'San Juan De Arama' },
    { name: 'Vista Hermosa' },
    { name: 'Villavicencio' },
    { name: 'Acacias' },
    { name: 'Barranca De Upia' },
    { name: 'Castilla La Nueva' },
    { name: 'Cumaral' },
    { name: 'El Calvario' },
    { name: 'Guamal' },
    { name: 'Restrepo' },
    { name: 'San Carlos Guaroa' },
    { name: 'San Juanito' },
    { name: 'San Luis De Cubarral' },
    { name: 'San Martín' },
    { name: 'Cabuyaro' },
    { name: 'Puerto Gaitán' },
    { name: 'Puerto Lopez' },
  ],
  Nariño: [
    { name: 'Chachagui' },
    { name: 'Consaca' },
    { name: 'El Peñol' },
    { name: 'El Tambo' },
    { name: 'La Florida' },
    { name: 'Nariño' },
    { name: 'Pasto' },
    { name: 'Sandoná' },
    { name: 'Tangua' },
    { name: 'Yacuanquer' },
    { name: 'Ancuya' },
    { name: 'Guaitarilla' },
    { name: 'La Llanada' },
    { name: 'Linares' },
    { name: 'Los Andes' },
    { name: 'Mallama' },
    { name: 'Ospina' },
    { name: 'Providencia' },
    { name: 'Ricaurte' },
    { name: 'Samaniego' },
    { name: 'Santa Cruz' },
    { name: 'Sapuyes' },
    { name: 'Tuquerres' },
    { name: 'Barbacoas' },
    { name: 'El Charco' },
    { name: 'Francisco Pizarro' },
    { name: 'La Tola' },
    { name: 'Magui' },
    { name: 'Mosquera' },
    { name: 'Olaya Herrera' },
    { name: 'Roberto Payan' },
    { name: 'Santa Barbara' },
    { name: 'Tumaco' },
    { name: 'Alban' },
    { name: 'Arboleda' },
    { name: 'Belen' },
    { name: 'Buesaco' },
    { name: 'Colon' },
    { name: 'Cumbitara' },
    { name: 'El Rosario' },
    { name: 'El Tablon De Gomez' },
    { name: 'La Cruz' },
    { name: 'La Union' },
    { name: 'Leiva' },
    { name: 'Policarpa' },
    { name: 'San Bernardo' },
    { name: 'San Lorenzo' },
    { name: 'San Pablo' },
    { name: 'San Pedro De Cartago' },
    { name: 'Taminango' },
    { name: 'Aldana' },
    { name: 'Contadero' },
    { name: 'Córdoba' },
    { name: 'Cuaspud' },
    { name: 'Cumbal' },
    { name: 'Funes' },
    { name: 'Guachucal' },
    { name: 'Gualmatan' },
    { name: 'Iles' },
    { name: 'Imues' },
    { name: 'Ipiales' },
    { name: 'Potosí' },
    { name: 'Puerres' },
    { name: 'Pupiales' },
  ],
  'Norte de Santander': [
    { name: 'Arboledas' },
    { name: 'Cucutilla' },
    { name: 'Gramalote' },
    { name: 'Lourdes' },
    { name: 'Salazar' },
    { name: 'Santiago' },
    { name: 'Villa Caro' },
    { name: 'Bucarasica' },
    { name: 'El Tarra' },
    { name: 'Sardinata' },
    { name: 'Tibú' },
    { name: 'Abrego' },
    { name: 'Cachirá' },
    { name: 'Convención' },
    { name: 'El Carmen' },
    { name: 'Hacarí' },
    { name: 'La Esperanza' },
    { name: 'La Playa' },
    { name: 'Ocaña' },
    { name: 'San Calixto' },
    { name: 'Teorama' },
    { name: 'Cúcuta' },
    { name: 'El Zulia' },
    { name: 'Los Patios' },
    { name: 'Puerto Santander' },
    { name: 'San Cayetano' },
    { name: 'Villa Del Rosario' },
    { name: 'Cácota' },
    { name: 'Chitagá' },
    { name: 'Mutiscua' },
    { name: 'Pamplona' },
    { name: 'Pamplonita' },
    { name: 'Silos' },
    { name: 'Bochalema' },
    { name: 'Chinácota' },
    { name: 'Durania' },
    { name: 'Herrán' },
    { name: 'Labateca' },
    { name: 'Ragonvalia' },
    { name: 'Toledo' },
  ],
  Putumayo: [
    { name: 'Colón' },
    { name: 'Mocoa' },
    { name: 'Orito' },
    { name: 'Puerto Asis' },
    { name: 'Puerto Caicedo' },
    { name: 'Puerto Guzman' },
    { name: 'Puerto Leguizamo' },
    { name: 'San Francisco' },
    { name: 'San Miguel' },
    { name: 'Santiago' },
    { name: 'Sibundoy' },
    { name: 'Valle Del Guamuez' },
    { name: 'Villa Garzon' },
  ],
  Quindío: [
    { name: 'Armenia' },
    { name: 'Buenavista' },
    { name: 'Calarca' },
    { name: 'Cordoba' },
    { name: 'Genova' },
    { name: 'Pijao' },
    { name: 'Filandia' },
    { name: 'Salento' },
    { name: 'Circasia' },
    { name: 'La Tebaida' },
    { name: 'Montengro' },
    { name: 'Quimbaya' },
  ],
  Risaralda: [
    { name: 'Dosquebradas' },
    { name: 'La Virginia' },
    { name: 'Marsella' },
    { name: 'Pereira' },
    { name: 'Santa Rosa De Cabal' },
    { name: 'Apía' },
    { name: 'Balboa' },
    { name: 'Belén De Umbría' },
    { name: 'Guática' },
    { name: 'La Celia' },
    { name: 'Quinchia' },
    { name: 'Santuario' },
    { name: 'Mistrató' },
    { name: 'Pueblo Rico' },
  ],
  Santander: [
    { name: 'Chima' },
    { name: 'Confines' },
    { name: 'Contratación' },
    { name: 'El Guacamayo' },
    { name: 'Galán' },
    { name: 'Gambita' },
    { name: 'Guadalupe' },
    { name: 'Guapotá' },
    { name: 'Hato' },
    { name: 'Oiba' },
    { name: 'Palmar' },
    { name: 'Palmas Del Socorro' },
    { name: 'Santa Helena Del Opón' },
    { name: 'Simacota' },
    { name: 'Socorro' },
    { name: 'Suaita' },
    { name: 'Capitanejo' },
    { name: 'Carcasí' },
    { name: 'Cepitá' },
    { name: 'Cerrito' },
    { name: 'Concepción' },
    { name: 'Enciso' },
    { name: 'Guaca' },
    { name: 'Macaravita' },
    { name: 'Málaga' },
    { name: 'Molagavita' },
    { name: 'San Andrés' },
    { name: 'San José De Miranda' },
    { name: 'San Miguel' },
    { name: 'Aratoca' },
    { name: 'Barichara' },
    { name: 'Cabrera' },
    { name: 'Charalá' },
    { name: 'Coromoro' },
    { name: 'Curití' },
    { name: 'Encino' },
    { name: 'Jordán' },
    { name: 'Mogotes' },
    { name: 'Ocamonte' },
    { name: 'Onzaga' },
    { name: 'Páramo' },
    { name: 'Pinchote' },
    { name: 'San Gil' },
    { name: 'San Joaquín' },
    { name: 'Valle De San José' },
    { name: 'Villanueva' },
    { name: 'Barrancabermeja' },
    { name: 'Betulia' },
    { name: 'El Carmen De Chucurí' },
    { name: 'Puerto Wilches' },
    { name: 'Sabana De Torres' },
    { name: 'San Vicente De Chucurí' },
    { name: 'Zapatoca' },
    { name: 'Bucaramanga' },
    { name: 'California' },
    { name: 'Charta' },
    { name: 'El Playón' },
    { name: 'Floridablanca' },
    { name: 'Girón' },
    { name: 'Lebríja' },
    { name: 'Los Santos' },
    { name: 'Matanza' },
    { name: 'Piedecuesta' },
    { name: 'Rionegro' },
    { name: 'Santa Bárbara' },
    { name: 'Surata' },
    { name: 'Tona' },
    { name: 'Vetas' },
    { name: 'Aguada' },
    { name: 'Albania' },
    { name: 'Barbosa' },
    { name: 'Bolívar' },
    { name: 'Chipatá' },
    { name: 'Cimitarra' },
    { name: 'El Peñón' },
    { name: 'Florián' },
    { name: 'Guavatá' },
    { name: 'Guepsa' },
    { name: 'Jesús María' },
    { name: 'La Belleza' },
    { name: 'La Paz' },
    { name: 'Landázuri' },
    { name: 'Puente Nacional' },
    { name: 'Puerto Parra' },
    { name: 'San Benito' },
    { name: 'Sucre' },
    { name: 'Vélez' },
  ],
  Sucre: [
    { name: 'Guaranda' },
    { name: 'Majagual' },
    { name: 'Sucre' },
    { name: 'Chalán' },
    { name: 'Coloso' },
    { name: 'Morroa' },
    { name: 'Ovejas' },
    { name: 'Sincelejo' },
    { name: 'Coveñas' },
    { name: 'Palmito' },
    { name: 'San Onofre' },
    { name: 'Santiago De Tolú' },
    { name: 'Tolú Viejo' },
    { name: 'Buenavista' },
    { name: 'Corozal' },
    { name: 'El Roble' },
    { name: 'Galeras' },
    { name: 'Los Palmitos' },
    { name: 'Sampués' },
    { name: 'San Juan Betulia' },
    { name: 'San Pedro' },
    { name: 'Sincé' },
    { name: 'Caimito' },
    { name: 'La Unión' },
    { name: 'San Benito Abad' },
    { name: 'San Marcos' },
  ],
  Tolima: [
    { name: 'Ambalema' },
    { name: 'Armero' },
    { name: 'Falan' },
    { name: 'Fresno' },
    { name: 'Honda' },
    { name: 'Mariquita' },
    { name: 'Palocabildo' },
    { name: 'Carmen De Apicalá' },
    { name: 'Cunday' },
    { name: 'Icononzo' },
    { name: 'Melgar' },
    { name: 'Villarrica' },
    { name: 'Ataco' },
    { name: 'Chaparral' },
    { name: 'Coyaima' },
    { name: 'Natagaima' },
    { name: 'Ortega' },
    { name: 'Planadas' },
    { name: 'Rioblanco' },
    { name: 'Roncesvalles' },
    { name: 'San Antonio' },
    { name: 'Alvarado' },
    { name: 'Anzoátegui' },
    { name: 'Cajamarca' },
    { name: 'Coello' },
    { name: 'Espinal' },
    { name: 'Flandes' },
    { name: 'Ibague' },
    { name: 'Piedras' },
    { name: 'Rovira' },
    { name: 'San Luis' },
    { name: 'Valle De San Juan' },
    { name: 'Alpujarra' },
    { name: 'Dolores' },
    { name: 'Guamo' },
    { name: 'Prado' },
    { name: 'Purificación' },
    { name: 'Saldaña' },
    { name: 'Suárez' },
    { name: 'Casabianca' },
    { name: 'Herveo' },
    { name: 'Lerida' },
    { name: 'Libano' },
    { name: 'Murillo' },
    { name: 'Santa Isabel' },
    { name: 'Venadillo' },
    { name: 'Villahermosa' },
  ],
  'Valle del Cauca': [
    { name: 'Andalucía' },
    { name: 'Buga' },
    { name: 'Bugalagrande' },
    { name: 'Calima' },
    { name: 'El Cerrito' },
    { name: 'Ginebra' },
    { name: 'Guacarí' },
    { name: 'Restrepo' },
    { name: 'Riofrio' },
    { name: 'San Pedro' },
    { name: 'Trujillo' },
    { name: 'Tuluá' },
    { name: 'Yotoco' },
    { name: 'Alcala' },
    { name: 'Ansermanuevo' },
    { name: 'Argelia' },
    { name: 'Bolívar' },
    { name: 'Cartago' },
    { name: 'El Águila' },
    { name: 'El Cairo' },
    { name: 'El Dovio' },
    { name: 'La Unión' },
    { name: 'La Victoria' },
    { name: 'Obando' },
    { name: 'Roldanillo' },
    { name: 'Toro' },
    { name: 'Ulloa' },
    { name: 'Versalles' },
    { name: 'Zarzal' },
    { name: 'Buenaventura' },
    { name: 'Caicedonia' },
    { name: 'Sevilla' },
    { name: 'Cali' },
    { name: 'Candelaria' },
    { name: 'Dagua' },
    { name: 'Florida' },
    { name: 'Jamundí' },
    { name: 'La Cumbre' },
    { name: 'Palmira' },
    { name: 'Pradera' },
    { name: 'Vijes' },
    { name: 'Yumbo' },
  ],
  Vaupés: [
    { name: 'Caruru' },
    { name: 'Mitú' },
    { name: 'Pacoa' },
    { name: 'Papunahua' },
    { name: 'Taraira' },
    { name: 'Yavaraté' },
  ],
  Vichada: [
    { name: 'Cumaribo' },
    { name: 'La Primavera' },
    { name: 'Puerto Carreño' },
    { name: 'Santa Rosalía' },
  ],
};
