<app-navbar-bg-black/>

<h2>
  Responda las siguientes<br />
  preguntas con la <br>
  información del negocio
</h2>

<section>
  <form class="container">
    <div class="container_question">
      <div class="question">
       @if(questions[0].disabled ){
          <h3 class="quest-10 disabled">¿Tiene más de un establecimiento de comercio? *</h3>
          <img
          src="./assets/images/icon-disabled.svg"
          alt="?"
          [attr.data-bs-toggle]="'modal'"
          [attr.data-bs-target]="'#exampleModal-' + questions[0].id"/>
        } @else {
          <h3 class="quest-10">¿Tiene más de un establecimiento de comercio? *</h3>
          <img
          src="./assets/images/icon.svg"
          alt="?"
          [attr.data-bs-toggle]="'modal'"
          [attr.data-bs-target]="'#exampleModal-' + questions[0].id"/>
        }


      </div>
      <div class="answer">
        <div>
          <label>Sí</label>
          <input
            role="radio"
            tabindex="1"
            (change)="getAnswer('si', questions[0].id)"
            class="input-checkbox"
            type="radio"
            id="{{ questions[0].id+'_establecimiento_answer' }}"
            name="{{ questions[0].id+'_establecimiento_answer' }}"
            value="si"
            [checked]="questions[0].checkedYes"
            [disabled]="questions[0].disabled"
            [required]="questions[0].required"
          />
      </div>
      <div>
        <label>No</label>
        <input
        role="radio"
        tabindex="2"
          (change)="getAnswer('no', questions[0].id)"
          class="input-checkbox"
          type="radio"
          id="{{ questions[0].id+'_establecimiento_answer' }}"
            name="{{ questions[0].id+'_establecimiento_answer' }}"
          [checked]="questions[0].checkedNo"
          [disabled]="questions[0].disabled"
          [required]="questions[0].required"
          value="no"
        />
      </div>

      </div>
    </div>
    @if(questions[0].error && questions[0].id === 25){
      <span class="error-inputs" role="alert">Campo requerido *</span>
    }

    <div class="container_question">
      <div class="question">

        @if(questions[1].disabled ){
          <h3 class="quest-10 disabled">¿Cuál fue el monto total de sus consignaciones bancarias, depósitos o inversiones financieras en el año inmediatamente anterior? *</h3>
        } @else {
          <h3 class="quest-10" style=" color: #186a88;">¿Cuál fue el monto total de sus consignaciones bancarias, depósitos o inversiones financieras en el año inmediatamente anterior? *</h3>
        }
      </div>

      <div class="answer-inputs-text">
        <div>
          @if(questions[1].disabled ){
            <label for="gravada-con-iva" class="disabled spaceLabel">De la actividad gravada con IVA *</label>

          }@else {
            <label for="gravada-con-iva">De la actividad gravada con IVA *</label>

          }
          <div class="answer-inputs-divs">
            <input
              role="textbox"
              tabindex="3"
              [attr.tabindex]="3"
              (input)="updateValue($event.target || '', questions[1].id)"
              [value]="cantidad"
              (keydown)="filtrarNumero($event)"
              class="input-checkbox"
              type="text"
              id="gravada-con-iva"
              name="gravada-con-iva"
              [required]="questions[1].required"
              [disabled]="questions[1].disabled"
              autocomplete="off"

            />

          </div>
        </div>

        <div>
          @if(questions[1].disabled ){
            <label for="gravada-con-iva-otro" class="disabled spaceLabel">Otras</label>

          }@else {
            <label for="gravada-con-iva-otro" class="spaceLabel">Otras</label>

          }

            <div class="answer-inputs-divs">
              <input
                role="textbox"
                tabindex="4"
                [attr.tabindex]="4"
                (input)="updateValue($event.target || '', questions[2].id)"
                (keydown)="filtrarNumero($event)"
                [value]="cantidadDos.trim()"
                class="input-checkbox"
                type="text"
                id="gravada-con-iva-otro"
                min="0"
                name="gravada-con-iva-otro"
                [required]="questions[2].required"
                [disabled]="questions[2].disabled"
                autocomplete="off"
              />
            </div>
        </div>

      </div>
    </div>
    @if(questions[1].error || questions[2].error){
      <p class="error-inputs" role="alert">Campo requerido *</p>
    }

    <div class="container_question">
      <div class="question">
        @if(questions[3].disabled){
          <h3 class="quest-10 disabled">{{ANSWER_EIGHT}} *</h3>

        } @else {
          <h3 class="quest-10">{{ANSWER_EIGHT}} *</h3>

        }

      </div>

      <div class="answer">
        <div>
          <label>Sí</label>
          <input
          role="radio"
          tabindex="4"
          (change)="getAnswer('si', questions[3].id)"
          class="input-checkbox"
          type="radio"
          id="{{ questions[3].id+'_compras_y_consumo_answer' }}"
          name="{{ questions[3].id+'_compras_y_consumo_answer' }}"
          value="si"
          [checked]="questions[3].checkedYes"
          [disabled]="questions[3].disabled"
          [required]="questions[3].required"
        />
        </div>
        <div>
          <label>No</label>
          <input
            role="radio"
            tabindex="5"
            (change)="getAnswer('no',questions[3].id)"
            class="input-checkbox"
            type="radio"
            id="{{ questions[3].id+'_compras_y_consumo_answer' }}"
            name="{{ questions[3].id+'_compras_y_consumo_answer' }}"
            [checked]="questions[3].checkedNo"
            [disabled]="questions[3].disabled"
            [required]="questions[3].required"
            value="no"
          />
      </div>
      </div>
    </div>
    @if(questions[3].error ){
      <p class="error-inputs" role="alert">Campo requerido *</p>
    }

    <div class="container_question">
      <div class="question">

        @if(questions[4].disabled){
          <h3 class="quest-10 disabled">¿Desarrolla su actividad económica bajo un sistema que implica la explotación de
            intangibles (por ejemplo, franquicia, concesión, regalía o similar)? *</h3>
          <img
          src="./assets/images/icon-disabled.svg"
          alt="?"
          [attr.data-bs-toggle]="'modal'"
          [attr.data-bs-target]="'#exampleModal-'+ questions[4].id"/>
        } @else {
          <h3 class="quest-10">¿Desarrolla su actividad económica bajo un sistema que implica la explotación de
            intangibles (por ejemplo, franquicia, concesión, regalía o similar)? *</h3>
          <img
          src="./assets/images/icon.svg"
          alt="?"
          [attr.data-bs-toggle]="'modal'"
          [attr.data-bs-target]="'#exampleModal-'+ questions[4].id"/>
        }
      </div>

      <div class="answer">
        <div>
          <label>Sí</label>
          <input
          role="radio"
            tabindex="6"
          (change)="getAnswer('si', questions[4].id)"
          class="input-checkbox"
          type="radio"
          id="{{ questions[4].id+'_explotacion_intangibles_answer' }}"
          name="{{ questions[4].id+'_explotacion_intangibles_answer' }}"
          value="si"
          [checked]="questions[4].checkedYes"
          [disabled]="questions[4].disabled"
          [required]="questions[4].required"
        />
        </div>
        <div>
          <label>No</label>
          <input
          role="radio"
            tabindex="7"
          (change)="getAnswer('no',questions[4].id)"
          class="input-checkbox"
          type="radio"
          id="{{ questions[4].id+'_explotacion_intangibles_answer' }}"
          name="{{ questions[4].id+'_explotacion_intangibles_answer' }}"
          [checked]="questions[4].checkedNo"
          [disabled]="questions[4].disabled"
          [required]="questions[4].required"
          value="no"
        />
        </div>

      </div>
    </div>
    @if(questions[4].error ){
      <p class="error-inputs" role="alert">Campo requerido *</p>
    }


    <div class="footer-form">
     <label>Recuerda que las respuestas son anónimas</label>
     <input type="submit" value="Continuar" (click)="handleSubmit($event)" id="button_continuar"/>
     <app-loading [loading]="loading"/>
    </div>
    @if(error){
      <div class="alert alert-danger" role="alert">
        {{error}}  Por favor haga <a href="/">clic aquí</a> para reiniciar el proceso y volver al inicio del aplicativo.
        <br>
        Cualquier inquietud puede comunicarse a la línea 57 (601) 307 8064 en el horario de atención: lunes a viernes 8:00 a. m. a 7:00 p. m. y sábados de 8:00 a. m. a 12:00 m.
      </div>
    }
    @for( quest of questions; track questions){
      <app-modal
        [id]="quest.id"
        [modalTitle]="quest.question"
        [modalContent]="quest.content"
      />

    }
  </form>

  <div class="image">
    <img src="/assets/icons/recurso-9.png" alt="imagen" />
  </div>
</section>
