<div
  class="modal fade"
  [id]="'exampleModal-' + id"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">

        <button
        role="button"
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body overflow-auto">
        @if(id === 22){

        <input
          type="text"
          (input)="getItems($event.target)"
          placeholder="Buscador..."
          class="inputFilter"
        />
        <a [href]="URL" target="_blank" >ver mas </a>

        @if(matchingSearch.length > 0){
          @for(items of matchingSearch; track matchingSearch; let idx = $index) {
        <div class="list-group">
          <button role="button" type="button" class="list-group-item list-group-item-action" >
            {{ idx + 1 }}. {{ items }}
          </button>
        </div>
        }
        } @else if (matchingSearch.length === 0 && showList) {
          <p>No encontro ningun resultado</p>

        } @else {
          @for( items of bienesExcluidos; track bienesExcluidos; let idx = $index){
            <div class="list-group">
              <button role="button" type="button" class="list-group-item list-group-item-action" >
                {{ items }}
              </button>
            </div>
            }
      }
       }

        @if(id === 24){

          <input
            type="text"
            (input)="getItemsDos($event.target)"
            placeholder="Buscador..."
            class="inputFilter"
          />
          <a [href]="URL" target="_blank" >ver mas </a>
          @if(matchingSearchDos.length > 0){
            @for(items of matchingSearchDos; track matchingSearchDos; let idx = $index) {
          <div class="list-group">
            <button role="button" type="button" class="list-group-item list-group-item-action">
              {{ idx + 1 }}. {{ items }}
            </button>
          </div>
          } }
          @else if (matchingSearchDos.length === 0 && showListDos) {
            <p>No encontro ningun resultado</p>

          } @else{


            @for( items of serviciosExcluidos; track serviciosExcluidos; let idx = $index){
              <div class="list-group">
                <button role="button" type="button" class="list-group-item list-group-item-action">
                  {{ idx + 1 }}. {{ items }}
                </button>
              </div>
              } } }

              @if(id === 'tipo-persona'){
                <p>Seleccione <b>“persona jurídica”</b> cuando la actividad económica se desarrolle a través de una sociedad,
                  por ejemplo, una Sociedad Anónima Simplificada (S.A.S) o seleccione <b>“persona natural”</b>
                  cuando actúe directamente sin utilizar una sociedad. Si representa una organización popular u organismo de
                    acción comunal, lo invitamos a dar click <b> <a href="https://www.dian.gov.co/impuestos/Documents/Guia-de-Obligaciones-Tributarias-para-Organismos-de-Accion-Comunal-y-Organizaciones-Populares.pdf " target="_blank">aqui</a></b>
                  </p>
              }

              @if(id === 'registro-unico-tributario'){
                <p>Consiste en un sistema que identifica, ubica y clasifica a entidades y personas con
                  actividades económicas. Centraliza datos para facilitar trámites ante la DIAN.
                  Es obligatorio inscribirse y mantener actualizada la información para el cumplimiento de
                  las responsabilidades tributarias. La obtención del RUT es gratuita y fácil. Si
                  representa una organización popular u organismo de acción comunal, lo invitamos a
                  dar clic <b> <a href="https://www.dian.gov.co/impuestos/Documents/Guia-de-Obligaciones-Tributarias-para-Organismos-de-Accion-Comunal-y-Organizaciones-Populares.pdf" target="_blank">aquí</a></b></p>
              }

        {{ modalContent }}
      </div>
      <div class="modal-footer">
        <button role="button" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          volver
        </button>
      </div>
    </div>
  </div>
</div>
