/* src/app/Presentation/pages/ayudanos/ayudanos.component.scss */
section {
  padding: 2rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  height: auto;
}
h2 {
  font-weight: 900;
  color: #262944;
  font-size: 3rem;
}
h3 {
  color: #047eb3;
  font-weight: 700;
  font-size: 2rem;
}
p {
  font-size: 1.5rem;
  color: #797979;
  max-width: 800px;
}
img {
  height: 20rem;
  width: auto;
}
button {
  border: none;
  background-color: #262944;
  color: white;
  font-weight: 800;
  width: 10rem;
  margin: 2rem 0px;
}
section div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
section div:last-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0px;
}
@media (max-width: 900px) {
  section {
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 2.5rem 1rem;
  }
  h2 {
    font-size: 1.5rem;
    width: 100%;
  }
  h3 {
    font-size: 1.3rem;
  }
  p {
    font-size: 1rem;
  }
  img {
    height: 10rem;
  }
}
/*# sourceMappingURL=ayudanos.component.css.map */
