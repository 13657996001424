/* src/app/Presentation/pages/form-seis/form-seis.component.scss */
.inputs-container input[type=radio]:disabled {
  border: 1px solid #a19ba8;
}
label.disabled,
legend.disabled {
  color: #a19ba8;
}
.formalized-business-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
  height: auto;
}
h2 {
  color: #006591;
  font-weight: 800;
  font-size: 2.5rem;
}
.content-image {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  gap: 6rem;
}
.image {
  padding: 2rem;
  width: auto;
  max-width: 22rem;
  height: 22rem;
}
.error {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
  color: red;
}
.ques-container {
  max-width: 35rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.ques-container .yes-no {
  display: flex;
  justify-content: flex-end;
}
.ques-container .yes-no div,
.ques-container .yes-no fieldset {
  width: 7.5rem;
  position: relative;
  right: 7px;
  display: inline-flex;
  justify-content: space-between;
  font-weight: 800;
}
.ques-container div,
.ques-container fieldset {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ques-container label,
.ques-container legend {
  width: 50%;
}
.inputs-container {
  width: 16rem;
}
.ques-container .see-results-btn {
  display: flex;
  justify-content: center;
}
.see-results-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.see-results-btn button {
  background-color: #006591;
  color: #ffffff;
  border: none;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  max-width: 18rem;
}
.inputs-container input[type=radio] {
  width: 24px;
  height: 24px;
  border: 1px solid #006591;
  appearance: none;
  border-radius: 5px;
  color: white;
  padding: 12px;
  cursor: pointer;
}
.inputs-container input[type=radio]:checked {
  background-image: url("./media/check-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #006591;
  color: white;
}
@media (max-width: 900px) {
  .formalized-business-container {
    flex-direction: column;
    padding: 1rem;
    height: auto;
  }
  .content-image {
    padding: 0px;
    gap: 0px;
    flex-direction: column-reverse;
  }
  .image {
    height: 10rem;
  }
}
/*# sourceMappingURL=form-seis.component.css.map */
