<app-navbar-bg-black/>
<section class="formalized-business-container">

  <div class="ques-container">
    <h2>Responda estas preguntas <br> para saber si su negocio <br>está formalizado</h2>
    <div class="yes-no">
      <div>
        <span>Sí</span><span>No</span>
      </div>
    </div>
<fieldset>

      @if(viewQuestOne()){
        <label tabindex="-1">¿Tiene Registro Único Tributario (RUT)? *</label>

      } @else{
        <label tabindex="-1" class="disabled">¿Tiene Registro Único Tributario (RUT)? *</label>
      }
        <div class="inputs-container">
          <img
            src="./assets/images/icon.svg"
            alt="?"
            [attr.data-bs-toggle]="'modal'"
            [attr.data-bs-target]="'#exampleModal-registro-unico-tributario'"
          />
          @if(viewQuestOne()){
            <input id="rut-si" name="rut" type="radio" role="radio" tabindex="1" value="si" (change)="getAnswer('si', 10)">
            <input id="rut-no" name="rut" type="radio" role="radio" tabindex="2" value="no" (change)="getAnswer('no', 10)">
          } @else{
            <input id="rut-si-d" name="rut" type="radio" role="radio" value="si" tabindex="1" [checked]="handleCheckedTwo()" (change)="getAnswer('si', 10)">
            <input id="rut-no-d" name="rut" type="radio" role="radio" value="no" tabindex="2" [disabled]="handleCheckedTwo()" (change)="getAnswer('no', 10)">
          }

    </div>
  </fieldset>
    @if(questions[0].error){
      <p class="error">campo requerido</p>
    }
    <app-modal
      [id]="'registro-unico-tributario'"
      [modalTitle]="''"
      [modalContent]="message"
  />

    <div>
      @if(viewQuestTwo()){
        <label for="registro_mercantil-si">¿Tiene Registro Mercantil? *</label>
      } @else {
        <label for="registro_mercantil-si" class="disabled">¿Tiene Registro Mercantil? *</label>
      }
      <div class="inputs-container">
        @if(viewQuestTwo()){
          <img
            src="./assets/images/icon.svg"
            alt="?"
            [attr.data-bs-toggle]="'modal'"
            [attr.data-bs-target]="'#exampleModal-tienes-registro-mercantil'"
          />
          <input id="registro_mercantil-si" name="registro_mercantil" type="radio" role="radio" tabindex="3" value="si" [checked]="handleCheckedTwo()" (change)="getAnswer('si', 11)">
          <input id="registro_mercantil-no" name="registro_mercantil" type="radio" role="radio" tabindex="4" value="no" [disabled]="handleCheckedTwo()" (change)="getAnswer('no', 11)">
          } @else{
            <img
              src="./assets/images/icon-disabled.svg"
              alt="?"
              [attr.data-bs-toggle]="'modal'"
              [attr.data-bs-target]="'#exampleModal-tienes-registro-mercantil'"
            />
            <input id="registro_mercantil" name="registro_mercantil" type="radio" role="radio" tabindex="3" value="si" [disabled]="true" (change)="getAnswer('si', 11)">
            <input id="registro_mercantil" name="registro_mercantil" type="radio" role="radio" tabindex="4" value="no" [disabled]="true" (change)="getAnswer('no', 11)">
          }
      </div>
    </div>
    @if(questions[1].error){
      <p class="error">campo requerido</p>
    }
    <app-modal
      [id]="'tienes-registro-mercantil'"
      [modalTitle]="''"
      [modalContent]="'Consiste en un sistema que recopila, registra y divulga información legal sobre los comerciantes. Funciona como un registro público administrado por las Cámaras de Comercio, donde se inscriben actos, documentos y contratos relacionados con la actividad comercial. Este registro es fundamental para la constitución, reconocimiento y operación legal de las empresas en Colombia, por lo que es obligatorio para todos los comerciantes y se debe renovar anualmente.'"
/>
    <div>
      @if(viewQuestThree()){
        <label for="answer yes-no-three">¿Renueva el Registro Mercantil cada año? *</label>
      } @else{
        <label for="answer yes-no-three" class="disabled">¿Renueva el Registro Mercantil cada año? *</label>
      }
        <div class="inputs-container">
          @if(viewQuestThree()){
            <div style="padding: 1.2rem;"></div>
            <input id="registro_mercantil_cada_anio" name="registro_mercantil_cada_anio" type="radio" role="radio" tabindex="5" value="si" (change)="getAnswer('si', 12)">
            <input id="registro_mercantil_cada_anio" name="registro_mercantil_cada_anio" type="radio" role="radio" tabindex="6" value="no" (change)="getAnswer('no', 12)">
          } @else {
            <div style="padding: 1.2rem;"></div>
            <input id="registro_mercantil_cada_anio" name="registro_mercantil_cada_anio" type="radio" value="si" [disabled]="true">
            <input id="registro_mercantil_cada_anio" name="registro_mercantil_cada_anio" type="radio" value="no" [disabled]="true">

          }
        </div>
    </div>
    @if(questions[2].error){
      <p class="error">campo requerido</p>
    }

    <div>
      @if(viewQuestFour()){
        <label for="answer yes-no-four" >¿Paga salud, pensiones y riesgos laborales de los trabajadores? *</label>
      } @else{
        <label for="answer yes-no-four" class="disabled">¿Paga salud, pensiones y riesgos laborales de los trabajadores? *</label>
      }
        <div class="inputs-container">
          @if(viewQuestFour()){
            <img
              src="./assets/images/icon.svg"
              alt="?"
              [attr.data-bs-toggle]="'modal'"
              [attr.data-bs-target]="'#exampleModal-paga-salud-trabajadores'"
            />
            <input id="paga_salud_trabajadores" name="paga_salud_trabajadores" type="radio" value="si" role="radio" tabindex="7" (change)="getAnswer('si', 13)">
            <input id="paga_salud_trabajadores" name="paga_salud_trabajadores" type="radio" value="no" role="radio" tabindex="8" (change)="getAnswer('no', 13)">
          } @else{
            <img
              src="./assets/images/icon-disabled.svg"
              alt="?"
              [attr.data-bs-toggle]="'modal'"
              [attr.data-bs-target]="'#exampleModal-paga-salud-trabajadores'"
            />
            <input id="paga_salud_trabajadores" name="paga_salud_trabajadores" type="radio" value="si" [disabled]="true">
            <input id="paga_salud_trabajadores" name="paga_salud_trabajadores" type="radio" value="no" [disabled]="true">

          }
        </div>
    </div>
    @if(questions[3].error){
      <p class="error">campo requerido</p>
    }
    <app-modal
    [id]="'paga-salud-trabajadores'"
    [modalTitle]="''"
    [modalContent]="'Consisten en contribuciones obligatorias que se deben realizar para garantizar el bienestar y protección de los empleados en distintas áreas de su vida laboral y personal. Estos aportes no solo aseguran cobertura en casos de enfermedad, invalidez o vejez, sino que también fortalecen la seguridad laboral, promoviendo un entorno de trabajo más seguro y una red de seguridad social robusta para todos los empleados.'"
/>

    <div>
      @if(viewQuestFive()){
        <label for="answer yes-no-five">¿Paga salud, pensiones y riesgos laborales? *</label>
      } @else {
        <label for="answer yes-no-five" class="disabled">¿Paga salud, pensiones y riesgos laborales? *</label>
      }
      <div class="inputs-container">
        @if(viewQuestFive()){
          <img
            src="./assets/images/icon.svg"
            alt="?"
            [attr.data-bs-toggle]="'modal'"
            [attr.data-bs-target]="'#exampleModal-paga-salud-laborales'"
          />
          <input id="paga-salud-laborales" name="paga-salud-laborales" type="radio" value="si" role="radio" tabindex="9" (change)="getAnswer('si', 14)">
          <input id="paga-salud-laborales" name="paga-salud-laborales" type="radio" value="no" role="radio" tabindex="10" (change)="getAnswer('no', 14)">

        } @else {
          <img
            src="./assets/images/icon-disabled.svg"
            alt="?"
            [attr.data-bs-toggle]="'modal'"
            [attr.data-bs-target]="'#exampleModal-paga-salud-laborales'"
          />
          <input id="paga-salud-laborales" name="paga-salud-laborales" type="radio" value="si" [disabled]="true">
          <input id="paga-salud-laborales" name="paga-salud-laborales" type="radio" value="no" [disabled]="true">

        }
      </div>
    </div>
    @if(questions[4].error){
      <p class="error">campo requerido</p>
    }

    <app-modal
    [id]="'paga-salud-laborales'"
    [modalTitle]="''"
    [modalContent]="'Consisten en contribuciones obligatorias que se liquidan sobre los ingresos que se reciben por las actividades que se realicen como independientes. Son esenciales para su bienestar, garantizando acceso a servicios médicos, seguridad económica en la jubilación y protección ante accidentes laborales.'"
/>

                <div>
                  <label for="answer yes-no-six">¿Conoce los requerimientos o permisos para desarrollar su actividad y cumple con estos? *</label>
                    <div class="inputs-container">
                      <div style="padding: 1.2rem;"></div>
                        <input id="requerimientos_permisos" name="requerimientos_permisos" type="radio" value="si" role="radio" tabindex="11" (change)="getAnswer('si', 15)">
                        <input id="requerimientos_permisos" name="requerimientos_permisos" type="radio" value="no" role="radio" tabindex="12" (change)="getAnswer('no', 15)">

                      </div>
                </div>
                @if(questions[5].error){
                  <p class="error">campo requerido</p>
                }
                <div>
                  <label for="answer yes-no-seven">¿Conoce los deberes y obligaciones tributarias de su actividad? *</label>
                    <div class="inputs-container">
                      <div style="padding: 1.2rem;"></div>
                        <input id="answer-seven" name="check-seven" type="radio" value="si" role="radio" tabindex="13" (change)="getAnswer('si', 16)">
                        <input id="answer-seven" name="check-seven" type="radio" value="no" role="radio" tabindex="14" (change)="getAnswer('no', 16)">
                    </div>
                </div>
                @if(questions[6].error){
                  <p class="error">campo requerido</p>
                }
<div>
  <p style="color: #006591">Recuerde que las respuestas son anónimas</p>
</div>
  </div>
  <div class="content-image">
    <img class="image" src="/assets/icons/recurso-3.png" alt="imagen">
    <div class="see-results-btn">
      <button
        (click)="handleSubmit($event)"
        id="ver_resultado"
        aria-label="Ver <br> Resultados"
        role="button"
        tabindex="99"
        >Ver <br> Resultados</button>
    </div>
    <app-loading [loading]="loading"/>

  </div>

    </section>
    @if(error){
      <div class="alert alert-danger" role="alert">
        {{error}}  Por favor haga <a href="/">clic aquí</a> para reiniciar el proceso y volver al inicio del aplicativo.
        <br>
        Cualquier inquietud puede comunicarse a la línea 57 (601) 307 8064 en el horario de atención: lunes a viernes 8:00 a. m. a 7:00 p. m. y sábados de 8:00 a. m. a 12:00 m.
      </div>
    }
