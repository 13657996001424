/* src/app/Presentation/pages/info-dos/info-dos.component.scss */
.content_container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  gap: 2rem;
  height: 60vh;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-image {
  width: 20rem;
  height: auto;
}
.error {
  color: red;
}
.selectError {
  display: flex;
  flex-direction: column;
}
h2 {
  padding: 2rem;
  color: #006591;
  font-size: xx-large;
  padding-top: 2rem;
  font-weight: 800;
}
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 3rem;
}
.container .form-container div {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.ques-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.container .ques-container select {
  border: none;
  border-bottom: 2px solid #02b0ef;
  background-color: #f0f8ff;
  text-align: center;
  width: 15rem;
  height: 2.5rem;
  font-size: medium;
  color: #006591;
  margin-right: 1rem;
  cursor: pointer;
}
.button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 20vh;
  padding: 1rem 5rem;
}
button {
  background-color: #006591;
  border: none;
  color: white;
  font-weight: 600;
  width: 10rem;
  height: 2.5rem;
}
@media (max-width: 900px) {
  .content_container {
    flex-direction: column;
    height: auto;
  }
  .container .ques-container select {
    width: 10rem;
  }
  .content-image {
    width: 10rem;
    height: auto;
  }
  label {
    font-size: 0.8rem;
  }
}
/*# sourceMappingURL=info-dos.component.css.map */
