/* src/app/Presentation/pages/formulario-dos/formulario-dos.component.scss */
h2 {
  font-weight: 900;
  line-height: 3rem;
  color: #186a88;
  font-size: 3rem;
  padding: 1rem;
}
h3 {
  font-size: 1rem;
}
.spaceLabel {
  text-align: right;
  width: 16rem;
}
section {
  display: flex;
  justify-content: center;
  width: 100%;
}
.disabled {
  color: #8e8e8e;
}
.disclaimer {
  width: 20%;
  font-size: 0.8rem;
  padding: 1rem;
  background-color: white;
}
.footer-form {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}
.footer-form label {
  color: #186a88;
}
.footer-form input[type=submit] {
  background-color: #186a88;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  font-weight: 800;
}
.answer-inputs-text div {
  display: flex;
}
.answer-inputs-divs {
  display: flex;
  flex-direction: column;
}
.error {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  color: #f77979;
  padding: 0px;
  margin: 0;
}
.error-inputs {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  color: #f77979;
  padding: 0px;
  margin: 0;
}
.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  gap: 1.5rem;
}
.image {
  position: absolute;
  right: 10rem;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 1rem;
}
.image img {
  width: 10rem;
  height: auto;
}
.container_question {
  display: flex;
  align-items: center;
  width: 100%;
}
.container_question .question {
  width: 50%;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.container_question .answer {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.container_question .answer div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container_question .answer div label {
  font-weight: 800;
}
.container_question .answer-inputs-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  left: -9rem;
}
.container_question .answer-inputs-text div {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
.container_question .answer-inputs-text div input {
  color: black;
  height: 2rem;
}
input[type=radio]:disabled {
  border: 1px solid #8e8e8e;
}
input[type=radio] {
  width: 32px;
  height: 32px;
  border: 1px solid #186a88;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 5px;
  color: white;
}
input[type=radio]:checked {
  background-image: url("./media/check-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #186a88;
  color: white;
}
.quest-10 {
  width: 50%;
}
@media (max-width: 900px) {
  h2 {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  section {
    flex-direction: column;
  }
  .quest-10 {
    width: 100%;
  }
  .container {
    padding: 1rem;
    gap: 1rem;
  }
  .footer-form {
    flex-direction: column;
  }
  .image {
    position: relative;
    right: auto;
  }
  .spaceLabel {
    text-align: right;
    width: 16rem;
  }
  .container_question {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }
  .container_question .question {
    width: 100%;
  }
  .container_question .answer {
    width: 100%;
  }
  .container_question .answer-inputs-text {
    width: 100%;
    left: 1px;
  }
  .container_question .answer-inputs-text div {
    display: flex;
    align-items: center;
  }
  .container_question .answer-inputs-text div label {
    font-size: 0.8rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .image {
    position: relative;
    right: auto;
  }
}
/*# sourceMappingURL=formulario-dos.component.css.map */
