/* src/app/Presentation/pages/formulario/formulario.component.scss */
h2 {
  font-weight: 900;
  line-height: 2rem;
  color: #0d6c8f;
  font-size: 2rem;
  padding: 1rem;
}
h3 {
  font-size: 1rem;
}
section {
  display: flex;
  justify-content: space-evenly;
}
.disabled {
  color: #8e8e8e;
}
.error {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  color: #f77979;
  padding: 0px;
  margin: 0;
}
.disclaimer {
  width: 20%;
  font-size: 0.8rem;
  padding: 1rem;
  background-color: white;
}
.footer-form {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 3rem;
}
.footer-form input[type=submit] {
  font-weight: 800;
  background-color: #0d6c8f;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
}
.container {
  width: 70%;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.container_question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}
.container_question .question {
  width: 80%;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1rem;
}
.container_question .answer {
  width: 20%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
input[type=radio].disabled {
  border: 1px solid #8e8e8e;
}
input[type=radio] {
  width: 32px;
  height: 32px;
  border: 1px solid #0d6c8f;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 5px;
  color: white;
}
input[type=radio]:checked {
  background-image: url("./media/check-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #0d6c8f;
  color: white;
}
.other-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.other-container img {
  width: 15rem;
}
label {
  font-weight: 800;
}
span {
  color: #0d6c8f;
}
@media (max-width: 900px) {
  section {
    flex-direction: column;
  }
  .disclaimer {
    width: 100%;
    padding: 1rem;
  }
  .container_question .answer {
    width: 200px;
  }
  .other-container {
    flex-direction: column-reverse;
  }
  .container,
  .other-container {
    gap: 1rem;
    width: 100%;
  }
  h2 {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  h3 {
    font-size: 0.8rem;
  }
  .footer-form {
    justify-content: center;
    align-items: center;
  }
}
/*# sourceMappingURL=formulario.component.css.map */
