export const answers = [
  {
    id: 1,
    quest: '¿Cuál es tu sexo?',
    value: '',
    error: false,
  },
  

  {
    id: 2,
    quest: '¿En qué rango de edad te encuentras?',
    value: '',
    error: false,
  },
  {
    id: 3,
    quest: 'Selecciona el departamento donde vives',
    value: '',
    error: false,
  },
  {
    id: 4,
    quest: 'Selecciona el municipio donde vives',
    value: '',
    error: false,
  },
  {
    id: 5,
    quest: '¿En qué tipo de zona vives?',
    value: '',
    error: false,
  },
  {
    id: 6,
    quest: '¿Qué uso le dará al simulador?',
    value: '',
    error: false,
  },
];
