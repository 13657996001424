<app-navbar-bg-black/>
<h2>
  Responda las siguientes <br>
  preguntas con la <br>
  información del negocio
</h2>
<section>

  <form class="container" >
    <div class="container_question">
      <div class="question"></div>
      <div class="answer">
        <label>Sí</label>
        <label>No</label>
        <label>No sé</label>
      </div>
    </div>
    @for( quest of questions; track questions){

    <div class="container_question">

      <app-quest [quest]="quest" class="question"/>

      <div class="answer">
        @if(quest.disabled === true){
        <input
          role="radio"
          (change)="getAnswer('si', quest.id)"
          class="input-checkbox disabled"
          type="radio"
          [id]="quest.id +'_answer'"
          [name]="quest.id +'_answer'"
          value="si"
          [checked]="quest.checkedYes"
          [disabled]="quest.disabled"
          [required]="quest.required"
        />
        <input
          role="radio"
          (change)="getAnswer('no', quest.id)"
          class="input-checkbox disabled"
          type="radio"
          [id]="quest.id +'_answer'"
          [name]="quest.id +'_answer'"
          [checked]="quest.checkedNo"
          [disabled]="quest.disabled"
          [required]="quest.required"
          value="no"
        />
        @if (quest.id === 20) {

        <input
          role="radio"
          (change)="getAnswer('no se', quest.id)"
          class="input-checkbox disabled"
          type="radio"
          [id]="quest.id +'_answer'"
          [name]="quest.id +'_answer'"
          [checked]="quest.checkedNose"
          name="{{ quest.id + 'nose' }}"
          value="no se"
          required
        />
        } } @else {

        <input
          role="radio"
          (change)="getAnswer('si', quest.id)"
          class="input-checkbox"
          type="radio"
          [id]="quest.id +'_answer'"
          [name]="quest.id +'_answer'"
          value="si"
          [checked]="quest.checkedYes"
          [disabled]="quest.disabled"
          [required]="quest.required"
        />

        <input
          role="radio"
          (change)="getAnswer('no', quest.id)"
          class="input-checkbox"
          type="radio"
          [id]="quest.id +'_answer'"
          [name]="quest.id +'_answer'"
          [checked]="quest.checkedNo"
          [disabled]="quest.disabled"
          [required]="quest.required"
          value="no"
        />
        @if (quest.id === 20) {

        <input
          role="radio"
          (change)="getAnswer('nose', quest.id)"
          class="input-checkbox"
          type="radio"
          [id]="quest.id +'_answer'"
          [name]="quest.id +'_answer'"
          [checked]="quest.checkedNose"
          value="nose"
          required
        />
        } }
      </div>

      <app-modal
        [id]="quest.id"
        [modalTitle]="quest.question"
        [modalContent]="quest.content"
        [URL]="URL"
      />
    </div>
    @if(quest.error){
      <p class="error" role="alert">Campo requerido *</p>
    }

    }


    <div class="footer-form">
      <span>Recuerde que las respuestas son anónimas</span>
    </div>
    @if(error){
      <div class="alert alert-danger" role="alert">
        {{error}}  Por favor haga <a href="/">clic aquí</a> para reiniciar el proceso y volver al inicio del aplicativo.
        <br>
        Cualquier inquietud puede comunicarse a la línea 57 (601) 307 8064 en el horario de atención: lunes a viernes 8:00 a. m. a 7:00 p. m. y sábados de 8:00 a. m. a 12:00 m.
      </div>
    }
  </form>

  <section class="other-container">
    <img src="/assets/icons/recurso-8.png" alt="imagen"/>

    <div class="footer-form">
      <input type="submit" value="Continuar" (click)="handleSubmit($event)" id="boton_continuar" tabindex="999" />
      <app-loading [loading]="loading"/>
    </div>

  </section>


</section>

