<app-navbar-bg-black/>
<section>
  <div>
    <h2>Su opinión es importante para mejorar <br> la experiencia de otros contribuyentes</h2>
    <!-- <h3></h3> -->
    <p>Es una encuesta breve que le agradecemos responder para prestar un mejor servicio a otras personas que van a empezar su proceso de fomalización tributaria.</p>
    <h3>¡Muchas Gracias!</h3>
  </div>

  <div>
    <img src="/assets/icons/recurso-6.png" alt="icon">
    <button (click)="handleClick()" aria-label="Responder <br> Encuesta" role="button" id="boton_continuar">Responder <br> Encuesta</button>
  </div>
</section>
