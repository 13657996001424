/* src/app/Presentation/pages/felicitaciones/felicitaciones.component.scss */
section {
  background-image: url(/assets/images/bg-desk.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.navbar {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: end;
}
section .congrats-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
h2 {
  color: white;
  font-weight: 800;
  font-size: 3rem;
}
img {
  width: auto;
  height: 25rem;
}
div {
  max-width: 900px;
}
button {
  border: none;
  background-color: #262944;
  color: white;
  font-weight: 800;
  width: 10rem;
  height: 3rem;
  margin: 2rem 0px;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
}
@media (max-width: 900px) {
  section {
    background-image: url(/assets/images/bg-mobile.png);
  }
  .congrats-container {
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    align-items: center;
  }
  img {
    height: 10rem;
  }
  h2 {
    padding-top: 3rem;
    font-size: 1.5rem;
  }
  div {
    display: flex;
    flex-direction: column;
  }
}
/*# sourceMappingURL=felicitaciones.component.css.map */
