
  @if(quest.disabled === true){
  <h3 class="disabled">
    {{ quest.question }}
  </h3>

    @if(viewimage()){
    <img
      src="./assets/images/icon-disabled.svg"
      alt="?"
      [attr.data-bs-toggle]="'modal'"
      [attr.data-bs-target]="'#exampleModal-' + quest.id"
    />
    } @else{
      <div class="space"></div>
    }
  } @else {
  <h3>
    {{ quest.question }}
  </h3>
    @if(viewimage()){
      <img
      src="./assets/images/icon.svg"
      alt="?"
      [attr.data-bs-toggle]="'modal'"
      [attr.data-bs-target]="'#exampleModal-' + quest.id"
    />
    }@else{
      <div class="space"></div>
    }
  }

