export const serviciosExcluidos = [
  'Los servicios médicos, odontológicos, hospitalarios, clínicos y de laboratorio, para la salud humana.',
  'Los servicios de administración de fondos del Estado y los servicios vinculados con la seguridad social de acuerdo con lo previsto en la Ley 100 de 1993.',
  'Los planes obligatorios de salud del sistema de seguridad social en salud expedidos por entidades autorizadas por la Superintendencia Nacional de Salud, los servicios prestados por las administradoras dentro del régimen de ahorro individual con solidaridad y de prima media con prestación definida, los servicios prestados por administradoras de riesgos laborales y los servicios de seguros y reaseguros para invalidez y sobrevivientes, contemplados dentro del régimen de ahorro individual con solidaridad a que se refiere el artículo 135 de la Ley 100 de 1993 o las disposiciones que la modifiquen o sustituyan.',
  'Las comisiones por intermediación por la colocación de los planes de salud del sistema general de seguridad social en salud expedidos por las entidades autorizadas legalmente por la Superintendencia Nacional de Salud, que no estén sometidos al impuesto sobre las ventas (IVA). ',
  'Los servicios de educación prestados por establecimientos de educación preescolar, primaria, media e intermedia, superior y especial o no formal, reconocidos como tales por el Gobierno nacional, y los servicios de educación prestados por personas naturales a dichos establecimientos. Están excluidos igualmente los servicios prestados por los establecimientos de educación relativos a restaurantes, cafeterías y transporte, así como los que se presten en desarrollo de las Leyes 30 de 1992 y 115 de 1994, o las disposiciones que las modifiquen o sustituyan. Igualmente están excluidos los servicios de evaluación de la educación y de elaboración y aplicación de exámenes para la selección y promoción de personal, prestados por organismos o entidades de la administración pública.',
  'Los servicios de educación virtual para el desarrollo de contenidos digitales, de acuerdo con la reglamentación expedida por el Ministerio de Tecnologías de la Información y las Comunicaciones, prestados en Colombia o en el exterior.',
  'Los servicios de conexión y acceso a internet de los usuarios residenciales del estrato 3.',
  'En el caso del servicio telefónico local, se excluyen del impuesto los primeros trescientos veinticinco (325) minutos mensuales del servicio telefónico local facturado a los usuarios de los estratos 1, 2 y 3 y el servicio telefónico prestado desde teléfonos públicos.',
  'El servicio de transporte público, terrestre, fluvial y marítimo de personas en el territorio nacional, y el de transporte público o privado nacional e internacional de carga marítimo, fluvial, terrestre y aéreo. Igualmente, se excluye el transporte de gas e hidrocarburos.',
  'El transporte aéreo nacional de pasajeros con destino o procedencia de rutas nacionales donde no exista transporte terrestre organizado. Esta exclusión también aplica para el transporte aéreo turístico con destino o procedencia al departamento de La Guajira y los municipios de Nuquí, en el departamento de Chocó, Mompox en el departamento de Bolívar, Tolú, en el departamento de Sucre, Miraflores en el departamento del Guaviare y Puerto Carreño en el departamento del Vichada.',
  'Los servicios públicos de energía. La energía y los servicios públicos de energía a base de gas u otros insumos.',
  'El agua para la prestación del servicio público de acueducto y alcantarillado, los servicios públicos de acueducto y alcantarillado, los servicios de aseo público y los servicios públicos de recolección de basuras.',
  'El gas para la prestación del servicio público de gas domiciliario y el servicio de gas domiciliario, ya sea conducido por tubería o distribuido en cilindros.',
  'Los servicios de alimentación, contratados con recursos públicos, destinados al sistema penitenciario, de asistencia social, de escuelas de educación pública, a las Fuerzas Militares, Policía Nacional, Centro de Desarrollo Infantil, centros geriátricos públicos, hospitales públicos, comedores comunitarios. 	',
  'El servicio de arrendamiento de inmuebles para vivienda y el arrendamiento de espacios para exposiciones y muestras artesanales nacionales, incluidos los eventos artísticos y culturales.',
  'Los intereses y rendimientos financieros por operaciones de crédito, siempre que no formen parte de la base gravable señalada en el artículo 447, y el arrendamiento financiero (leasing).',
  'Los servicios de intermediación para el pago de incentivos o transferencias monetarias condicionadas en el marco de los programas sociales del Gobierno nacional.',
  'Las boletas de entrada a cine, a los eventos deportivos, culturales, incluidos los musicales y de recreación familiar. También se encuentran excluidos los servicios de que trata el Artículo 6o de la Ley 1493 de 2011.',
  'Los servicios funerarios, los de cremación, inhumación y exhumación de cadáveres, alquiler y mantenimiento de tumbas y mausoleos.',
  'Adquisición de licencias de software para el desarrollo comercial de contenidos digitales, de acuerdo con la reglamentación expedida por el Ministerio de Tecnologías de la Información y Comunicaciones.',
  'Suministro de páginas web, servidores (hosting), computación en la nube (cloud computing).',
  'Las comisiones pagadas por los servicios que se presten para el desarrollo de procesos de titularización de activos a través de universalidades y patrimonios autónomos cuyo pago se realice exclusivamente con cargo a los recursos de tales universalidades o patrimonios autónomos.',
  'Las comisiones percibidas por las sociedades fiduciarias, sociedades administradoras de inversión y comisionistas de bolsa por la administración de fondos de inversión colectiva.',
  'Los siguientes servicios, siempre que se destinen a la adecuación de tierras, a la producción agropecuaria y pesquera y a la comercialización de los respectivos productos:',
  'a) El riego de terrenos dedicados a la explotación agropecuaria;',
  'b) El diseño de sistemas de riego, su instalación, construcción, operación, administración y conservación;',
  'c) La construcción de reservorios para la actividad agropecuaria;',
  'd) La preparación y limpieza de terrenos de siembra;',
  'e) El control de plagas, enfermedades y malezas, incluida la fumigación aérea y terrestre de sembradíos;',
  'f) El corte y recolección manual y mecanizada de productos agropecuarios;',
  'g) Aplicación de fertilizantes y elementos de nutrición edáfica y foliar de los cultivos;',
  'h) Aplicación de sales mineralizadas;',
  'i) Aplicación de enmiendas agrícolas;',
  'j) Aplicación de insumos como vacunas y productos veterinarios;',
  'k) El pesaje y el alquiler de corrales en ferias de ganado mayor y menor;',
  'l) La siembra;',
  'm) La construcción de drenajes para la agricultura;',
  'n) La construcción de estanques para la piscicultura;',
  'o) Los programas de sanidad animal;',
  'p) La perforación de pozos profundos para la extracción de agua;',
  'q) El desmonte de algodón, la trilla y el secamiento de productos agrícolas;',
  'r) La selección, clasificación y el empaque de productos agropecuarios sin procesamiento industrial',
  's) La asistencia técnica en el sector agropecuario;',
  't) La captura, procesamiento y comercialización de productos pesqueros;',
  'u) El servicio de recaudo de derechos de acceso vehicular a las centrales mayoristas de abasto.',
  'La comercialización de animales vivos, excepto los animales domésticos de compañía.',
  'El servicio de faenamiento.',
  'Están excluidos de IVA los servicios de hotelería y turismo que sean prestados en los municipios que integran las siguientes zonas de régimen aduanero especial:',
  'a) Zona de régimen aduanero especial de Urabá, Tumaco y Guapi.',
  'b) Zona de régimen aduanero especial de Inírida, Puerto Carreño, La Primavera y Cumaribo.',
  'c) Zona de régimen aduanero especial de Maicao, Uribia y Manaure.',
  'Las operaciones cambiarias de compra y venta de divisas, así como las operaciones cambiarias sobre instrumentos derivados financieros.',
  'Las comisiones percibidas por la utilización de tarjetas crédito y débito.',
  'Los servicios de promoción y fomento deportivo prestados por los clubes deportivos definidos en el artículo 2o del Decreto Ley 1228 de 1995.',
  'Los servicios de reparación y mantenimiento de naves y artefactos navales tanto marítimos como fluviales de bandera colombiana, excepto los servicios que se encuentran en el literal P) del numeral 3 del artículo 477 de este Estatuto.',
  'Los servicios de publicidad en periódicos que registren ventas en publicidad a 31 de diciembre del año inmediatamente anterior inferiores a 180.000 UVT.',
  'La publicidad en las emisoras de radio cuyas ventas sean inferiores a 30.000 UVT al 31 de diciembre del año inmediatamente anterior y programadoras de canales regionales de televisión cuyas ventas sean inferiores a 60.000 UVT al 31 de diciembre del año inmediatamente anterior. Aquellas que superen este monto se regirán por la regla general.',
  'Los servicios de corretaje de contratos de reaseguros.',
  'Los seguros tomados en el exterior, para amparar riesgos de transporte, barcos, aeronaves y vehículos matriculados en Colombia, así como bienes situados en territorio nacional y los seguros que en virtud de la Ley 1328 de 2009 sean adquiridos en el exterior, estarán gravados con el impuesto sobre las ventas a la tarifa general, cuando no se encuentren gravados con este impuesto en el país de origen. Cuando en el país en el que se tome el seguro, el servicio se encuentre gravado con el impuesto sobre las ventas a una tarifa inferior a la indicada en el inciso anterior, se causará el impuesto con la tarifa equivalente a la diferencia entre la aplicable en Colombia y la del correspondiente país. Los seguros de casco, accidentes y responsabilidad a terceros, de naves o aeronaves destinadas al transporte internacional de mercancías y aquellos que se contraten por el Fondo de Solidaridad y Garantía creado por la Ley 100 de 1993 tomados en el país o en el exterior, no estarán gravados con el impuesto sobre las ventas.',
];
