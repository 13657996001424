/* src/app/Presentation/pages/info-tres/info-tres.component.scss */
section {
  background-image: url(/assets/images/bg-desk.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: auto;
}
.title-container {
  display: flex;
  align-items: center;
  justify-content: start;
}
.title-container img {
  padding: 1rem;
  width: 15rem;
  color: white;
}
h2 {
  color: white;
  font-weight: 800;
  font-size: 3rem;
  padding: 6rem 0px 0px 7rem;
}
h3 {
  color: #272944;
  font-weight: 600;
  font-size: 2.3rem;
  padding: 0rem 0px 0px 7rem;
}
section p {
  font-size: large;
}
.two-options {
  padding: 3rem 0px;
}
section .two-options {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
section .two-options button {
  color: #f0f8ff;
  background-color: #272944;
  border: none;
  width: 25rem;
  height: 8rem;
  padding: 1rem;
  font-size: larger;
  border-radius: 2rem;
}
section .two-options button:hover {
  background-color: #f0f8ff;
  color: #333333;
  font-weight: 500;
}
.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  color: white;
}
.warning img {
  width: 4rem;
  padding: 1rem;
}
.warning p {
  font-size: 1rem;
}
section .warning a {
  text-decoration: none;
  color: white;
}
.warning img {
  margin-top: -20px;
}
@media (max-width: 900px) {
  section {
    background-image: url(/assets/images/bg-mobile.png);
    background-size: cover;
  }
  h2 {
    font-size: 1.3rem;
    padding: 6rem 0px 0px 1rem;
  }
  h3 {
    font-size: 1rem;
    padding: 0rem 0px 0px 1rem;
  }
  .two-options {
    flex-direction: column;
  }
  .two-options button {
    width: auto !important;
  }
  .title-container img {
    display: none;
  }
  .warning {
    padding: 1.4rem;
    width: 100%;
    font-size: 0.8rem;
    max-width: 350px;
  }
  .warning img {
    display: none;
  }
}
/*# sourceMappingURL=info-tres.component.css.map */
