<app-navbar-bg-black/>
<h2>Descripción del negocio</h2>
<section class="content_container">
  <div class="container">
    <section class="form-container">
      <div class="ques-container">
        <label for="business">Titular del negocio *</label>
        <div>
          <img
            src="./assets/images/icon.svg"
            alt="?"
            [attr.data-bs-toggle]="'modal'"
            [attr.data-bs-target]="'#exampleModal-tipo-persona'"
          />
          <div class="selectError">
            <select
              role="listbox"
              tabindex="1"
              name="titular_negocio"
              id="titular_negocio"
              [(ngModel)]="titular.value"
              (change)="getAnswer($event, 1, 'Titular del negocio')"
              role="emplea">
              <option value="">Seleccione</option>
              <option value="persona natural">Persona Natural</option>
              <option value="persona juridica">Persona Jurídica</option>
            </select>
            @if(titular.error){
              <p class="error">el campo es requerido*</p>
            }
          </div>
        </div>
      </div>
      <div class="ques-container">
        <label for="tipo_negocio">Clasifique el negocio de acuerdo con su actividad *</label>
        <div>
          <img
            src="./assets/images/icon.svg"
            alt="?"
            [attr.data-bs-toggle]="'modal'"
            [attr.data-bs-target]="'#exampleModal-actividad-comercial'"
          />
          <div class="selectError">
            <select
            role="listbox"
            tabindex="2"
              [(ngModel)]="negocio.value"
              [disabled]="titular.value === 'persona juridica'"
              name="tipo_negocio"
              id="tipo_negocio"
              (change)="getAnswer($event, 2, 'Clasifica el negocio de acuerdo a la Actividad del negocio')">
              <option value="">Seleccione</option>
              <option value="comercial">Comercial</option>
              <option value="profesion liberal o artistica">
                Profesión liberal o artística
              </option>
              <option value="agricultura o ganaderia">
                Agricultura y ganadería
              </option>
            </select>
            @if(negocio.error){
              <p class="error">el campo es requerido*</p>
            }
          </div>
        </div>
      </div>

      <div class="ques-container yesNo">
        <label for="emplea">¿Emplea trabajadores? *</label>
        <div class="selectError">
          <select
          role="listbox"
          tabindex="3"
            name="emplea"
            id="emplea"
            (change)="getAnswer($event, 3, '¿Emplea trabajadores?')">
            <option value="">Seleccione</option>
            <option value="si">Sí</option>
            <option value="no">No</option>
          </select>
          @if(trabajadores.error){
            <p class="error">el campo es requerido*</p>
          }
        </div>
      </div>

    </section>
  </div>

  <div class="container">
    <img class="content-image" src="/assets/icons/recurso-2.png" alt="imagen"/>
  </div>
</section>
<div class="button" >
  <button
  tabindex="3"
  (click)="handleClick()"
    aria-label="Continuar"
    role="button"
    id="boton_continuar">Continuar</button>
  <app-loading [loading]="loading"/>
</div>
@if(error){
  <div class="alert alert-danger" role="alert">
    {{error}}  Por favor haga <a href="/">clic aquí</a> para reiniciar el proceso y volver al inicio del aplicativo.
    <br>
    Cualquier inquietud puede comunicarse a la línea 57 (601) 307 8064 en el horario de atención: lunes a viernes 8:00 a. m. a 7:00 p. m. y sábados de 8:00 a. m. a 12:00 m.
  </div>
}
<app-modal
  [id]="'tipo-persona'"
  [modalTitle]="''"
  [modalContent]="mensaje"
/>

<app-modal
  [id]="'actividad-comercial'"
  [modalTitle]="''"
  [modalContent]="mensajeDos"
/>
