/* src/app/Presentation/pages/home/home.component.scss */
.container_home {
  background-image: url(/assets/images/bg-desk.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
}
h2 {
  font-size: xx-large;
  font-weight: 600;
  padding-bottom: 1rem;
  color: #f0f8ff;
  background-color: #0098da;
}
h1 {
  max-width: 30rem;
  background-color: white;
  padding: 1rem;
  border-left: 1rem solid #262944;
  font-size: 2.5rem;
  font-weight: 900;
}
.content-container-rigth img {
  width: 15rem;
  height: 20rem;
}
.container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px 3rem;
  gap: 1rem;
}
.content-container-left,
.content-container-rigth {
  max-width: 35rem;
}
.introduction-container p {
  font-size: x-large;
  padding-bottom: 1rem;
  color: #f0f8ff;
}
.start-ques-container {
  color: #262944;
  font-weight: 600;
  font-size: x-large;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.recaptcha {
  padding: 1rem 0px;
}
button {
  font-size: 1.3rem;
  font-weight: 800;
  background-color: #262944;
  color: #f0f8ff;
  border: none;
  width: 10rem;
  height: auto;
  padding: 1rem;
}
.home-footer {
  display: flex;
  gap: 10rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;
}
.home-footer div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-footer p {
  color: white;
  font-weight: 600;
}
@media (max-width: 900px) {
  section {
    height: auto;
  }
  .container {
    height: auto;
    flex-direction: column;
    padding-bottom: 3rem;
  }
  .container_home {
    background-image: url(/assets/images/bg-mobile.png);
    background-size: cover;
    height: auto;
  }
  .home-footer {
    flex-direction: column;
  }
  .content-container-rigth img {
    width: auto;
    height: 8rem;
  }
  .video {
    display: none;
  }
  .introduction-container p {
    font-size: 1.2rem;
  }
  .start-ques-container {
    font-size: 1rem;
  }
}
/*# sourceMappingURL=home.component.css.map */
