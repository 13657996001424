import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarComponent } from '../../components/navbar';
import { FormatedURLService } from 'app/Infraestructure/formatedURL/formated-url.service';
import { URL } from './constants';
import { validateToken } from '../../utils/validateToken';

@Component({
  selector: 'app-beneficios',
  standalone: true,
  templateUrl:
    './../../../../Presentation/pages/beneficios/beneficios.component.html',
  styleUrl:
    './../../../../Presentation/pages/beneficios/beneficios.component.scss',
  imports: [NavbarComponent],
})
class BeneficiosComponent implements OnInit {
  URL: string = URL;
  error = '';
  constructor(
    private router: Router,
    private serviceParametrization: FormatedURLService
  ) {
    validateToken(this.router);
  }

  ngOnInit(): void {
    this.serviceParametrization.getFormatedURL().subscribe({
      next: (response) => {
        if (response) {
          const { URL3 } = response;
          this.URL = URL3;
        }
      },
      error: (error) => {
        console.error('Error response:', error);
        if (error.error.isTrusted) {
          this.error =
            'Error 500: ha ocurrido un error en el servidor. Por favor, haga ';
        } else {
          this.error = error.error || 'An unexpected error occurred';
        }
      },
    });
  }

  public handleClick() {
    this.router.navigate(['felicitaciones']);
  }
}

export default BeneficiosComponent;
