export const formatedResponse = (input: any) => {
  const regex: RegExp = /\D/g;
  return input.replace(regex, '');
};

export const formatedResponseData = (input: any) => {
  let cantidadSinSimbolo = input.replace('$', '').trim();
  let cantidadNumerica = parseFloat(
    cantidadSinSimbolo.replace(/\./g, '').replace(',', '.')
  );
  let numeroSinDecimales = Math.trunc(cantidadNumerica);

  return numeroSinDecimales;
};
