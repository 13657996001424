import { Router } from '@angular/router';

export const validateToken = (router: Router) => {
  const token = window.sessionStorage.getItem('token');
  const dateToken = window.sessionStorage.getItem('dateToken') || Date.now();

  const expirationTime: any = Number(dateToken);
  const timeDifference = Number(Date.now()) - expirationTime;

  if (token) {
    if (timeDifference >= 20 * 60 * 1000) {
      sessionStorage.clear();
      alert('Tu sesión ha expirado. Serás redirigido al inicio.');
      router.navigate(['/']);
    }
  }
};
