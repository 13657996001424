<app-navbar-bg-black />
<section>
  <div>
    <h2>
      Por favor, responda a las siguientes preguntas. Solo podrá
      escoger una opción de respuesta por pregunta.
    </h2>
  </div>

  <div>
    <h3>En una escala del 1 a 5, donde uno es muy malo y 5 muy<br /> bueno, la <b>experiencia de uso del simulador:</b>
    </h3>
    <app-rating id="calificacion_uno" name="rate1" id_1="rate1_1" id_2="rate1_2" id_3="rate1_3" id_4="rate1_4"
      id_5="rate1_5" (valueResponse)="getAnswer($event, 42)" />
  </div>

  <div>
    <h3>En una escala del 1 a 5, donde uno es muy malo y 5 muy util, la<br /> <b>información arrojada por el
        simulador:</b></h3>
    <app-rating  id="calificacion_dos" name="rate2" id_1="rate2_1" id_2="rate2_2" id_3="rate2_3" id_4="rate2_4"
      id_5="rate2_5" (valueResponse)="getAnswer($event, 43)" />
  </div>

  <div>
    <h3>En una escala del 1 a 5, donde 1 es muy dificil y <br /> 5 muy facil, la <b>el acceso al simulador fue:</b></h3>
    <app-rating id="calificacion_tres" name="rate3" id_1="rate3_1" id_2="rate3_2" id_3="rate3_3" id_4="rate3_4"
      id_5="rate3_5" (valueResponse)="getAnswer($event, 44)" />
  </div>

  <div>
    <h3><b>¿Recomendaría el simulador a amigos o familiares <br /> que quieran formalizar
        su negocio?</b></h3>
    <span style="display:flex;">
      <div class="checkbox-select">
        <label for="recomendaria_si">Sí</label>
        <input role="radio" tabindex="6" class="input-checkbox disabled" type="radio" id="recomendaria_si" name="recomendaria" value="si"
          (change)="getAnswer('si',45)" />
      </div>
      <div class="checkbox-select">
        <label for="recomendaria_no">No</label>
        <input tabindex="7" role="radio" class="input-checkbox disabled" type="radio" id="recomendaria_no" name="recomendaria" value="no"
          (change)="getAnswer('no',45)" />
      </div>
    </span>
  </div>

  <div>
    <h3>¿En qué considera que podríamos mejorar para hacer un proceso más amable <br /> y eficiente para nuestros
      usuarios? <br /> Déjenos un comentario en el recuadro.
    </h3>
    <textarea tabindex="8" role="textbox" id="opinion" name="opinion" rows="4" cols="43" (change)="getAnswer($event,46)"></textarea>
  </div>

  <div>
    <button (click)="handleClick()" aria-label="Enviar" role="button" id="boton_continuar" tabindex="9">Enviar</button>
    <app-loading [loading]="loading" />
    @if(error){
      <div class="alert alert-danger" role="alert">
        {{error}} Por favor haga <a href="/">clic aquí</a> para reiniciar el proceso y volver al inicio del aplicativo.
        <br>
        Cualquier inquietud puede comunicarse a la línea 57 (601) 307 8064 en el horario de atención: lunes a viernes 8:00 a. m. a 7:00 p. m. y sábados de 8:00 a. m. a 12:00 m.
      </div>
    }
  </div>
</section>
