<nav role="navigation">
<a
  role="button"
  tabindex="-1"
  href="https://www.gov.co/"
  target="_blank"
  title="Ir al portal GOV.CO"
  aria-label="Portal del Estado Colombiano - GOV.CO">
  GOV.CO
</a>

</nav>
