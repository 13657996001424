/* src/app/Presentation/pages/beneficios/beneficios.component.scss */
section {
  display: flex;
  justify-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: rgb(227, 215, 239);
  background-image: url(/assets/images/bg-desk.png);
  background-size: cover;
}
.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info .column2 {
  padding: 1rem;
  margin-left: 2rem;
  width: 70%;
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 5rem;
  gap: 2rem;
}
.footer img {
  width: 15rem;
  background-color: #0098da;
}
h2 {
  padding: 1rem 5rem;
  font-size: 2rem;
  font-weight: 900;
  color: #272944;
}
h1 {
  padding: 1rem 1rem;
  font-size: 2rem;
  font-weight: 900;
  color: #272944;
  background-color: white;
  border-left: 1rem solid #272944;
  max-width: 44rem;
  margin-left: 5rem;
}
h5 {
  margin-left: 1rem;
  font-size: 1.5rem;
  font-weight: 800;
  padding: 1rem;
  color: #272944;
}
ol {
  list-style: none;
  counter-reset: item;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
li {
  color: #272944;
  counter-increment: item;
  display: flex;
  gap: 1rem;
}
li p {
  text-align: justify;
}
li:before {
  color: #272944;
  content: counter(item);
  font-weight: bold;
}
h3 {
  background-color: #0098da;
  font-size: 1.2rem;
  max-width: 48rem;
  color: white;
}
span,
a {
  text-decoration: none;
  color: #272944;
}
button {
  background-color: #272944;
  border: none;
  color: white;
  width: 10rem;
  height: 3rem;
}
@media (max-width: 900px) {
  section {
    background-image: url(/assets/images/bg-mobile.png);
    background-size: 100% 100%;
  }
  ol {
    padding: 0px;
  }
  h2 {
    font-size: 1.3rem;
    padding: 1rem;
  }
  h1 {
    margin: 0px;
    max-width: 20rem;
  }
  .info {
    flex-direction: column;
  }
  .info .column2 {
    margin-top: 1rem;
    margin-left: 0rem;
    width: 90%;
  }
  .column2 {
    height: 30rem;
    width: 100%;
    overflow-y: auto;
    overflow-x: none;
    background-color: #22bcf2;
    border-radius: 1rem;
  }
  .footer {
    padding: 1rem;
  }
}
/*# sourceMappingURL=beneficios.component.css.map */
