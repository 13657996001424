/* src/app/Presentation/pages/form-cuatro/form-cuatro.component.scss */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.container .info-left-container h2 {
  font-weight: 800;
  font-size: xx-large;
  color: #09303e;
}
h3 {
  font-size: 1.3rem;
  font-weight: 800;
  color: #02b0ef;
}
.container .info-left-container p {
  font-weight: 500;
  margin-bottom: 1rem;
}
.instructions-paragraph {
  color: #919191;
}
.container .options-right-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 85vh;
  width: 32rem;
  padding-bottom: 1rem;
}
.container .options-right-container div {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.container .options-right-container h3 {
  font-size: x-large;
}
.container .options-right-container input[type=radio] {
  width: 24px;
  height: 24px;
  border: 1px solid #2e6386;
  appearance: none;
  border-radius: 5px;
  color: white;
  padding: 12px;
  cursor: pointer;
}
.container .options-right-container input[type=radio]:checked {
  background-image: url("./media/check-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #2e6386;
  color: white;
}
.container .options-right-container label {
  cursor: pointer;
}
button {
  height: 3rem;
  width: 8rem;
  font-size: medium;
  font-weight: 500;
  border: none;
  background-color: #2e6386;
  font-weight: 800;
  color: #f0f8ff;
  cursor: pointer;
  margin-top: 1rem;
}
button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: #666666;
}
.other-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.other-container img {
  padding: 2rem;
  width: 25rem;
  height: auto;
}
.other-container span {
  padding-top: 1rem;
  color: #2e6386;
}
.other-container div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 3rem;
}
@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }
  .options-right-container {
    gap: 2rem;
  }
  .container .options-right-container {
    width: 100%;
    height: auto;
  }
  .other-container {
    height: 30rem;
    flex-direction: column-reverse;
  }
  .other-container img {
    width: 10rem;
  }
  .other-container div {
    height: auto;
    gap: 2rem;
    flex-direction: column;
  }
}
/*# sourceMappingURL=form-cuatro.component.css.map */
