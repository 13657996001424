import { Component } from '@angular/core';

@Component({
  selector: 'app-navbar-two',
  standalone: true,
  imports: [],
  templateUrl:
    './../../../../Presentation/components/navbar-two/navbar-two.component.html',
  styleUrl:
    './../../../../Presentation/components/navbar-two/navbar-two.component.scss',
})
export class NavbarTwoComponent {}
