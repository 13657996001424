<app-navbar-bg-black/>

<div class="container-info">
<article>
  <h1>Información del Usuario</h1>
</article>
<section>
  <div class="ques-container">
    <div>
      <label for="select_genero">Sexo *</label>
      <ng-select
        tabindex="0"
        role="listbox"
        dropdownPosition="bottom"
        (change)="getAnswer(1, $event)"
        (clear)="clearAnswer(1, {name: ''})"
        [items]="[{name:'Mujer'}, {name:'Hombre'}, {name:'Indeterminado'}]"
        bindLabel="name"
        bindValue="name"
        id="select_genero"
        name="select_genero"
        placeholder="- seleccione -"
        [(ngModel)]="selectedSexo"
        labelForId="sexo">
      </ng-select>
      <img src="/assets/icons/select/recurso-select-1.png" alt="icon" />
    </div>
    @if(answers[0].error){
      <div><p class="error">campo requerido *</p></div>
    }

    <div>
      <label for="rango_de_edad">Rango *<br> de edad</label>
      <ng-select
      tabindex="1"
        role="listbox"
        dropdownPosition="bottom"
        (change)="getAnswer(2, $event)"
        (clear)="clearAnswer(2, {name: ''})"
        [items]="[{name:'18 a 29'}, {name:'30 a 49'}, {name:'50 a más'}]"
        bindLabel="name"
        bindValue="name"
        id="rango_de_edad"
        name="rango_de_edad"
        placeholder="- seleccione -"
        [(ngModel)]="selectedEdad"
        labelForId="edad">
      </ng-select>
      <img src="/assets/icons/select/recurso-select-2.png" alt="icon" />

      </div>
      @if(answers[1].error){
        <div><p class="error">campo requerido *</p></div>
      }

      <div>
        <label for="uso_que_le_dara">¿Qué uso le dará *<br> al simulador?</label>
        <ng-select
        tabindex="2"
        role="listbox"

        dropdownPosition="bottom"
        (change)="getAnswer(6, $event)"
        (clear)="clearAnswer(6, {name: ''})"
        [items]="[{name:'Proyectar un negocio'}, {id: 22, name:'Enseñar'} , {name:'Evaluar un negocio en curso'}, {name: 'Aprender'}]"
        bindLabel="name"
        bindValue="name"
        id="uso_que_le_dara"
        name="uso_que_le_dara"
        placeholder="- seleccione -"
        [(ngModel)]="selectedUso"
        labelForId="uso">
      </ng-select>
      <img src="/assets/icons/select/recurso-select-6.png" alt="icon" />


      </div>
      @if(answers[5].error){
        <div><p class="error">campo requerido *</p></div>
      }

  </div>
  <div class="ques-container">
    <div>
      <label for="departamentos">Departamento *<br> dónde vive</label>
      <ng-select
      tabindex="3"
        role="listbox"
        dropdownPosition="bottom"
        (change)="getAnswer(3, $event)"
        (clear)="clearAnswer(3, {name: ''})"
        [items]="departamentos"
        bindLabel="name"
        bindValue="name"
        id="departamentos"
        name="departamentos"
        placeholder="- seleccione -"
        [(ngModel)]="selectedDepartamentos"
        [loading]="loading"
        [virtualScroll]="true"
        labelForId="departamento"
        appendTo="body">
      </ng-select>
      <img src="/assets/icons/select/recurso-select-3.png" alt="icon" />

    </div>
    @if(answers[2].error){
      <div><p class="error">campo requerido *</p></div>
    }
            <div>
              <label for="municipios">Municipio *<br> dónde vive</label>
              <ng-select
              tabindex="4"
              role="listbox"
                dropdownPosition="bottom"
                (change)="getAnswer(4, $event)"
                (clear)="clearAnswer(4, {name: ''})"
                [items]="municipios"
                bindLabel="name"
                bindValue="name"
                name="municipios"
                id="municipios"
                placeholder="- seleccione -"
                [(ngModel)]="selectedMunicipio"
                labelForId="municipio">
              </ng-select>
              <img src="/assets/icons/select/recurso-select-4.png" alt="icon" />

              </div>
              @if(answers[3].error){
                <div><p class="error">campo requerido *</p></div>
              }


            <div>
              @if(disabledRural){
                <label class="disabled" for="zona">Zona *<br> dónde vive</label>

              } @else{
                <label class="" for="zona">Zona *<br> dónde vive</label>
              }

              <ng-select
                tabindex="5"
                role="listbox"
                dropdownPosition="bottom"
                [disabled]="disabledRural"
                (change)="getAnswer(5, $event)"
                (clear)="clearAnswer(5, {name: ''})"
                [items]="zone"
                bindLabel="name"
                bindValue="name"
                id="zona"
                name="zona"
                placeholder="- seleccione -"
                [(ngModel)]="selectedZona"
                labelForId="zona">
              </ng-select>
              <img src="/assets/icons/select/recurso-select-5.png" alt="icon" />

            </div>
            @if(answers[4].error){
              <div><p class="error">campo requerido *</p></div>
            }



        </div>

    </section>
    <div class="button" id="continuar">
      <button (click)="handleClick()" aria-label="Continuar" role="button" id="boton_continuar">Continuar</button>
      <app-loading [loading]="loading"/>
    </div>
    @if(error){
      <div class="alert alert-danger" role="alert">
        {{error}}  Por favor haga <a href="/">clic aquí</a> para reiniciar el proceso y volver al inicio del aplicativo.
        <br>
        Cualquier inquietud puede comunicarse a la línea 57 (601) 307 8064 en el horario de atención: lunes a viernes 8:00 a. m. a 7:00 p. m. y sábados de 8:00 a. m. a 12:00 m.
      </div>
    }
  </div>
