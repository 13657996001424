<section>
  <app-navbar />
  <h2>
    Usted tiene otra opción para cumplir<br>
    sus obligaciones:
  </h2>
  <h1>Régimen Simple de Tributación</h1>
  <div class="info">

    <div class="column2">
      <h5>Estos son sus beneficios de acogerse:</h5>
      <ol>
        <li>
          <p>
            Usted no estará sujeto a Retenciones en la Fuente a título de Renta ni a título de ICA,(así
            mismo no le corresponde practicar retenciones en la fuente por estos conceptos) salvo las
            retenciones que se deban practicar por concepto de pagos laborales.
          </p>
        </li>
        <li>
          <p>
            Tarifa de entre 1.2% y 14.5% de acuerdo con del valor de los ingresos brutos obtenidos y
            de la actividad que realice.
          </p>
        </li>
        <li>
          <p>
            Una sola declaración le facilita liquidar y pagar varios impuestos, como impuesto simple,
            Impuesto Nacional al consumo y también Impuesto de Industria y Comercio.
          </p>
        </li>
        <li>
          <p>
            El procedimiento de presentación de las declaraciones y recibos es digital: puede declarar
            y pagar a través de medios electrónicos.
          </p>
        </li>
        <li>
          <p>
            Si solo desarrolla actividades de tiendas pequeñas, mini-mercados, micro-mercados y
            peluquerías, no será responsable del Impuesto sobres las Ventas (IVA). (parágrafo 4 art.
            437 del ET.).
          </p>
        </li>
        <li>
          <p>
            Podrá descontar (restar) del anticipo e impuesto, los aportes a pensión que haga por sus
            trabajadores (12%).
          </p>
        </li>
        <li>
          <p>
            Quedará exento de aportes parafiscales tales como SENA, ICBF y aportes a Salud.
          </p>
        </li>
        <li>
          <p>
            Podrá descontar (restar) del impuesto, el 0.5% de los pagos recibidos a través de tarjetas
            de crédito o débito u otros medios electrónicos en los términos legales.
          </p>
        </li>
        <li>
          <p>
            Podrá (restar) del impuesto lo pagado por Gravamen a los Movimientos Financieros, o
            cuatro por mil (4 x 1.000) en los términos legales. (Excluyentes con el anterior beneficio, no
            acumulativos).
          </p>
        </li>
        <li>
          <p>
            No presentación de anticipos bimestrales si los ingresos no superan los 3.500 UVT
            ($164.727.500 año 2024), solo declaración anual consolidada.
          </p>
        </li>
      </ol>
    </div>

    <div class="footer">
      <h3>
        Para
        obtener más información sobre cómo <br />optar por este régimen
        <a [href]="URL"
          target="_blank"> oprime aquí</a>
      </h3>
      <button (click)="handleClick()" aria-label="Continuar" role="button" id="boton_continuar">Continuar</button>
    </div>
  </div>

</section>
