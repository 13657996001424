
<section>
  <div class="navbar">
    <app-navbar/>
  </div>

  <div class="container">
    <div class="congrats-container">
        <div>
          <h2>Felicidades <br> Usted ha completado el recorrido.</h2>
          <h3>Un negocio formalizado contribuye con el país, la comunidad y crea valor a sus propietarios.</h3>
        </div>
        <div>
            <img src="/assets/icons/recurso-4.png" alt="image"/>
            <button (click)="handleClick()" aria-label="Continuar" role="button" id="boton_continuar">Continuar</button>
        </div>
      </div>
    </div>
  </section>
