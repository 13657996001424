/* src/app/Presentation/components/rating/rating.component.scss */
.rating {
  display: flex;
  flex-direction: row-reverse;
}
.rating:not(:checked) > input {
  appearance: none;
}
input,
input:focus,
input:hover {
  outline: auto;
}
.rating:not(:checked) > label {
  cursor: pointer;
  color: white;
}
input[type=radio]:focus {
  outline: auto;
}
.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: yellow;
}
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: yellow;
}
.rating > input:checked ~ label {
  color: yellow;
}
.rating > input:focus ~ label {
  outline: auto;
}
/*# sourceMappingURL=rating.component.css.map */
