/* src/app/Presentation/components/navbar/navbar.component.scss */
nav {
  display: flex;
  height: 4rem;
  width: 100%;
  padding: 4rem 4rem 0px 0px;
  background-color: transparent;
  position: sticky;
  justify-content: end;
  align-items: end;
  gap: 1rem;
}
nav h2 {
  font-size: 1rem;
  font-weight: 800;
  color: #262944;
  padding: 0px;
  margin: 0px;
  padding: 0px 10px;
  display: flex;
  border-right: 2px solid white;
}
nav img {
  height: 1rem;
}
@media (max-width: 900px) {
  nav {
    padding: 1rem;
  }
  nav h2 {
    display: flex;
    justify-content: end;
    align-items: end;
    font-size: 0.8rem;
  }
}
/*# sourceMappingURL=navbar.component.css.map */
