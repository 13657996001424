/* src/app/Presentation/pages/info/info.component.scss */
article {
  padding: 3rem;
}
.container-info {
  height: auto;
}
select {
  outline: auto;
}
section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
h1 {
  color: #006591;
  font-size: xx-large;
  font-weight: 900;
}
article p {
  color: #02b0ef;
  width: 70vh;
  margin-bottom: 2rem;
}
.error {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  color: red;
}
.disabled {
  color: rgb(130, 130, 130);
}
.ques-container {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  height: 25rem;
}
.ques-container div {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
  width: 27rem;
}
.ques-container div img {
  width: 3rem;
}
.button {
  display: flex;
  justify-content: flex-end;
  padding: 3rem 1rem;
  width: 100%;
  max-width: 1100px;
}
button {
  background-color: #02B0F0;
  border: none;
  color: white;
  font-weight: 600;
  padding: 0.5rem 1rem;
}
label {
  text-align: right;
}
@media (max-width: 900px) {
  .container-info {
    height: auto;
  }
  section {
    width: 100%;
    flex-direction: column;
  }
  label {
    font-size: 0.8rem;
    text-align: right;
  }
  .ques-container,
  .ques-container div {
    justify-content: end;
    width: 100%;
    height: auto;
  }
  .button {
    display: flex;
    justify-content: center;
    max-width: auto;
    padding: 5rem 0px;
  }
}
/*# sourceMappingURL=info.component.css.map */
