
    <section id="stepper-dos">
      <app-navbar-two/>
        <div class="info-container">
                <h2>Esta es la ruta de la formalización de su negocio</h2>
                <p>No olvide que esta es una aproximación de las obligaciones de su negocio y pueden existir otras
                  obligaciones tributarias que se deban cumplir.</p>


        </div>
<div class="stepper-dos" >
<div>
  <div class="empty-space"></div>
  <div class="empty-space"></div>
  <div class="step-uno">
    <h2>Regístrese</h2>
    <div class="container-step">
      @if(viewButtonOne()){
        <div class="stepper-item completed">
          <div class="stepper-container-item" >
            <div class="step-counter" >1</div>
            <div class="step-name" >Registro<br>Mercantil</div>
          </div>
        </div>
      }

      <div class="stepper-item completed">
        <div class="stepper-container-item">
          <div class="step-counter">2</div>
          <div class="step-name">Registro <br>Único<br> Tributario</div>
        </div>
      </div>


      <div class="stepper-item">
        <div class="stepper-container-item" >
          <div class="step-counter">3</div>
          <div class="step-name">Registro de <br> información <br> tributaria</div>
        </div>
      </div>
    </div>
  </div>

</div>
<div>
  <div class="empty-space"></div>
  <div class="step-uno">
    <h2 style="color: #02b0ef;">Organice su <br> información</h2>
    <div class="container-step">

        <div class="stepper-item">
          <div class="stepper-container-item">
            <div class="step-counter">4</div>
            <div class="step-name">Contabilidad o <br> libro fiscal</div>
          </div>
        </div>


        @if(viewButtonFive()){
          <div class="stepper-item completed">
              <div class="stepper-container-item" >
                <div class="step-counter">5</div>
                <div class="step-name">Sistema de<br> facturación<br> electrónica</div>
              </div>
          </div>
        }


        <div class="stepper-item">
          <div class="stepper-container-item" >
              <div class="stepper-container-item" >
                <div class="step-counter">6</div>
                <div class="step-name">Informar</div>
              </div>
          </div>
        </div>
    </div>
  </div>


</div>

<div>

  <div class="step-uno">
    <h2>Pague sus <br> impuestos</h2>
    <div class="container-step">
      @if(viewButtonSeven()){
        <div class="stepper-item">
          <div class="stepper-container-item">
            <div class="step-counter">7</div>
            <div class="step-name">Impuesto <br> sobre la  <br>renta</div>
          </div>
        </div>
      }

      @if(viewButtonEigth()){
        <div class="stepper-item">
          <div class="stepper-container-item" >
            <div class="step-counter">8</div>
            <div class="step-name">Impuesto <br/>
              al valor <br/>
              agregado</div>
          </div>
        </div>
      }


      @if(viewButtonNine()){
        <div class="stepper-item completed">
          <div class="stepper-container-item" >
            <div class="step-counter">9</div>
            <div class="step-name">Impuesto <br> de industria  <br>y comercio</div>
          </div>
        </div>
      }
      @if(viewButtonTen()){
      <div class="stepper-item completed">
        <div class="stepper-container-item">
          <div class="step-counter">10</div>
          <div class="step-name">Impuesto <br> al consumo</div>
        </div>
      </div>
      }
    </div>
  </div>

</div>
</div>
        <div class="button">
          <img class="img1" src="/assets/icons/recurso-12.png" alt="imagen" />
          <article class="buttons-content">
            <div>
              <p>Descargue el siguiente <br> archivo para consultar <br> cómo cumplir con sus<br> obligaciones tributaria</p>
              <a
                role="button"
                tabindex="1"
                id="boton_descarga_uno"
                href="/assets/Cartilla - publicaciones con comentarios aplicados (05-06-24).pdf"
                download="/assets/Cartilla - publicaciones con comentarios aplicados (05-06-24).pdf">
                <img class="img2" src="/assets/icons/downloadfile.svg" alt="imagen" />
              </a>
            </div>
            <div>
                <p>Mi resultado <br></p>
                <img
                  tabindex="2"
                  role="button"
                  (click)="screenShot()"
                  class="img2"
                  src="/assets/icons/downloadfile.svg"
                  alt="imagen"
                  id="boton_descarga_dos"/>
            </div>
          </article>
          <button (click)="handleClick()" aria-label="Continuar" role="button" tabindex="3" id="boton_continuar">Continuar</button>

        </div>
        @if(loading){
          <div class="alert alert-warning" role="alert">
            Se va a descargar tu archivo. Espere un momento.
            <div class="spinner-border text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        }

    </section>

