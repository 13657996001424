
<app-navbar-bg-black/>
    <section class="container">
      <div>

        <section class="info-left-container">
            <h2>Modelos</h2>
            <p class="info-paragraph">
              A continuación encontrará varios rangos de ingresos y patrimonios que
              permitirán simular las condiciones de su negocio.</p>
            <p class="instructions-paragraph">
              Una vez seleccione un rango de ingresos y patrimonio en el que se ubique su
              negocio obtendrá la “ruta de la formalización”.</p>
        </section>
        <section class="options-right-container">
            <div class="title-options-year">
                <h3>Los ingresos brutos al año de su negocio son *</h3>
                <img
              src="./assets/images/icon.svg"
              alt="?"
              [attr.data-bs-toggle]="'modal'"
              [attr.data-bs-target]="'#exampleModal-ingreso'"
            />
            </div>

            <div>
              <input id="answer-one" name="check-one" type="radio" role="radio" tabindex="1" (change)="getAnswer(true,47, ANSWER_ONE)">
              <label for="answer-one">
                {{ ANSWER_ONE }}
              </label>
              </div>
            <div>
              <input id="answer-two" name="check-one" type="radio" role="radio" tabindex="2"  (change)="getAnswer(true,47, ANSWER_TWO)">
              <label for="answer-two">
                {{ANSWER_TWO}}
              </label>
            </div>
            <div>
              <input id="answer-three" name="check-one" type="radio" role="radio" tabindex="3"  (change)="getAnswer(true,47, ANSWER_THREE)">
              <label for="answer-three">
                {{ANSWER_THREE}}
              </label>
            </div>
            <div>
              <input id="answer-four" name="check-one" type="radio" role="radio" tabindex="4" (change)="getAnswer(true,47, ANSWER_FOUR)">
              <label for="answer-four"> {{ANSWER_FOUR}}</label>
            </div>


            <div class="title-options-year">
              <h3>El patrimonio bruto de su negocio es</h3>
              <img
              src="./assets/images/icon.svg"
              alt="?"
              [attr.data-bs-toggle]="'modal'"
              [attr.data-bs-target]="'#exampleModal-patrimonio'"
            />
          </div>
            <div>
              <input id="answer-five" name="check-two" type="radio" role="radio" tabindex="5" (change)="getAnswer(true,48, ANSWER_FIVE)">
              <label for="answer-five">
                {{ANSWER_FIVE}}
              </label>
            </div>
            <div>
              <input id="answer-six" name="check-two" type="radio" role="radio" tabindex="6" (change)="getAnswer(true,48, ANSWER_SIX)">
              <label for="answer-six">
                {{ANSWER_SIX}}
              </label>
            </div>
            <div>
              <input id="answer-seven" name="check-two" type="radio" role="radio" tabindex="7" (change)="getAnswer(true,48, ANSWER_SEVEN)">
              <label for="answer-seven"> {{ANSWER_SEVEN}}</label>
            </div>



        </section>
      </div>
      <div class="other-container">
        <img src="/assets/icons/recurso-7.png" alt="imagen"/>

        <div>
          <span>Recuerde que las respuestas son anónimas</span>
          <button
          tabindex="8"
            [disabled]="avalible === true"
            (click)="handleClickButton()"
            aria-label="Continuar"
            role="button"
            id="boton_continuar">Continuar
          </button>
          <app-loading [loading]="loading"/>


        </div>

      </div>
    </section>
    @if(error){
      <div class="alert alert-danger" role="alert">
        {{error}}  Por favor haga <a href="/">clic aquí</a> para reiniciar el proceso y volver al inicio del aplicativo.
        <br>
        Cualquier inquietud puede comunicarse a la línea 57 (601) 307 8064 en el horario de atención: lunes a viernes 8:00 a. m. a 7:00 p. m. y sábados de 8:00 a. m. a 12:00 m.
      </div>
    }
    <app-modal
    [id]="'ingreso'"
    [modalTitle]="''"
    [modalContent]="'Los ingresos brutos son todas las entradas de dinero de su negocio, como puede ser lo que reciba por venta de mercancías, productos agrícolas o por la prestación de servicios. '"
/>
<app-modal
[id]="'patrimonio'"
[modalTitle]="''"
[modalContent]="'El patrimonio bruto es la suma de todos los bienes y activos como inventarios, maquinarias y equipos, vehículos, casas, locales, dinero de cuentas, pagarés a su favor, entre otros, que su negocio tenga. A esa suma no le puede restar deudas.'"
/>
