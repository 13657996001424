/* src/app/Presentation/components/footer/footer.component.scss */
footer {
  display: flex;
  width: 100%;
  padding: 3rem 1rem;
  justify-content: space-around;
  align-items: center;
  background-color: #3666CC;
  height: auto;
}
.img-content a {
  content: url(https://cdn.www.gov.co/assets/images/logo.svg);
  height: 2rem;
  width: 8.5rem;
}
.column-1,
.column-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid white;
  height: 15rem;
  color: white;
  font-size: 0.8rem;
}
.column-1 ul,
.column-2 ul {
  list-style: none;
}
a {
  color: white;
}
a:hover {
  font-weight: bold;
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.footer_2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  height: auto;
  color: white;
  font-size: 0.8rem;
  background-color: #3666CC;
  padding: 1rem;
}
.img-footer {
  border-left: 1px solid white;
  padding-left: 0.5rem;
}
.he_footer_redes {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media (max-width: 900px) {
  .img-content {
    padding: 1rem;
  }
  footer {
    padding: 1rem;
    height: auto;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
  .column-1,
  .column-2 {
    width: 100%;
    height: auto;
    border: none;
    align-items: start;
    justify-content: start;
  }
}
/*# sourceMappingURL=footer.component.css.map */
