const answer = [
  {
    id: 30,
    value: '',
    text: '¿Cuál fue su patrimonio bruto el año anterior?',
  },
  {
    id: 31,
    value: '',
    text: '¿Cuál es su patrimonio bruto del presente año?',
  },
  { id: 32, value: '', text: '¿Ingresos de actividades gravadas con IVA?' },
  {
    id: 33,
    value: '',
    text: 'Ingresos de actividades de restaurante, cafeterías, autoservicio, bares y similares',
  },
  { id: 34, value: '', text: 'Ingresos Laborales' },
  { id: 35, value: '', text: 'Otros ingresos' },
  { id: 36, value: '', text: 'Total de ingresos brutos' },
  { id: 37, value: '', text: '¿Ingresos de actividades gravadas con IVA?' },
  {
    id: 38,
    value: '',
    text: 'Ingresos de actividades de restaurante, cafeterías, autoservicio, bares y similares',
  },
  { id: 39, value: '', text: 'Ingresos Laborales' },
  { id: 40, value: '', text: 'Otros ingresos' },
  { id: 41, value: '', text: 'Total de ingresos brutos' },
];

export { answer };
