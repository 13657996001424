/* src/app/Presentation/components/question/question.component.scss */
h2 {
  font-weight: 900;
  line-height: 3rem;
  color: #02B0F0;
  font-size: 3rem;
}
h3 {
  font-size: 1rem;
  text-align: right;
  width: 100%;
}
.disabled {
  color: #8e8e8e;
}
.space {
  padding: 1rem;
}
/*# sourceMappingURL=question.component.css.map */
