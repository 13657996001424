    <section class="container_home">
    <app-firstnavbar/>
    <section class="container">
        <section class="content-container-left">
            <div class="introduction-container">
                <h2>Bienvenido al</h2>
                <h1>Simulador de <br/> formalización</h1>
                <p>
                  Aquí podrá evaluar si su negocio es formal o <br>
                  informal y conocer la ruta de la <br>
                  formalización tributaria
                </p>
            </div>
            <div class="start-ques-container">
                <p>
                  Las respuestas son completamente <br>
                  anónimas, es decir, usted no tendrá <br>
                  que identificarse. Responda con libertad y explore
                  diferentes escenarios
                </p>

            </div>
        </section>
        <div class="content-container-rigth">
            <img src="/assets/icons/recurso-1.png" alt="icono"/>
        </div>
    </section>

    <div class="home-footer">
      <div>
      <button (click)="handleClick()" id="boton_iniciar" aria-label="Iniciar" role="button">Iniciar</button>
      @if(viewRecaptcha){

        <div class="recaptcha">
          <re-captcha (resolved)="resolved($event)" [siteKey]="recaptcha"></re-captcha>
        </div>
      }
      <app-loading [loading]="loading"/>
      @if(error){
      <div class="alert alert-danger" role="alert">
        {{error}}
      </div>
    }
    </div >
    </div>
  </section>
