/* src/app/Presentation/pages/formulario-tres/formulario-tres.component.scss */
.container {
  display: flex;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
  justify-content: center;
}
.header-questions {
  display: flex;
  width: 100%;
  gap: 1rem;
}
.disabled {
  color: #8e8e8e;
}
.container .info-container p {
  color: #0098DA;
  font-size: larger;
  font-weight: 500;
  line-height: 1.7rem;
  max-width: 15rem;
}
.container .ques-container {
  display: flex;
  flex-direction: column;
  font-size: large;
  font-weight: 500;
  gap: 1.5rem;
}
.container .ques-container .span {
  color: #0098DA;
  padding-right: 7rem;
  font-weight: 800;
}
.container .ques-container div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.container .ques-container div label {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.container .ques-container input.input {
  border: 1px solid #06698d;
  width: 13rem;
  height: 2rem;
  padding-left: 5px;
}
.container .ques-container .icon {
  margin-right: 1.5rem;
}
.container .ques-container label {
  margin-right: 2rem;
}
.container .ques-container .large-ques {
  width: 21rem;
}
h2 {
  font-weight: 900;
  line-height: 2rem;
  color: #06698d;
  font-size: 2rem;
  padding: 1rem;
}
img {
  margin: 0px 1rem;
}
.footer-form {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 1rem;
  gap: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer-form button {
  background-color: #06698d;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  font-weight: 800;
}
.other-container {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  justify-content: space-between;
}
.other-container img {
  width: 20rem;
  height: auto;
}
.text-questions {
  display: flex;
}
span {
  color: #06698d;
  font-size: 1rem;
}
@media (max-width: 321px) {
  .bruto {
    font-size: 0.5rem;
  }
}
b {
  font-weight: 900;
  font-size: 1.3rem;
}
@media (max-width: 900px) {
  h2 {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
  .ques-container {
    display: flex;
    flex-direction: column;
  }
  .container {
    flex-direction: column;
  }
  .other-container {
    flex-direction: column-reverse;
  }
  .other-container img {
    width: 15rem;
    height: auto;
  }
  .container .ques-container div {
    justify-content: center;
    align-items: center;
  }
  .container .ques-container div label {
    font-size: 1rem;
    margin: 0px;
    width: 100%;
    justify-content: start;
    align-items: center;
  }
  .container .ques-container div .bruto {
    font-size: 0.7rem;
  }
  .container .ques-container .span {
    padding: 0px;
  }
}
/*# sourceMappingURL=formulario-tres.component.css.map */
