import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl:
    './../../../../Presentation/components/footer/footer.component.html',
  styleUrl:
    './../../../../Presentation/components/footer/footer.component.scss',
})
export class FooterComponent {}
