/* src/app/Presentation/pages/descubre/descubre.component.scss */
section {
  background-image: url(/assets/images/bg-desktop_2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}
h2 {
  background-color: #272944;
}
.navbar {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: end;
}
.discover-more-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.discover-more-container h2 {
  color: #fff;
  font-size: 2rem;
  font-weight: 800;
  display: flex;
  width: 55%;
  justify-content: start;
  align-items: start;
}
.discover-more-container p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  max-width: 60%;
  padding: 1rem;
  color: #02b0ef;
}
.social-networks-container {
  display: flex;
  width: 45%;
  justify-content: space-evenly;
}
.social-networks-container svg {
  width: 5rem;
  height: 5rem;
  background-color: transparent;
  border-radius: 50%;
  border: 3px solid #02b0ef;
  padding: 10px;
}
.discover-more-container button {
  background-color: #02b0ef;
  border: none;
  color: #fff;
  font-size: 1.3rem;
  width: 10rem;
  font-weight: 800;
}
@media (max-width: 900px) {
  section {
    background-image: url(/assets/images/bg-mobile-2.png);
    background-size: cover;
  }
  .discover-more-container {
    height: auto;
    padding: 1rem;
    height: auto;
    background-color: #272944;
  }
  .discover-more-container h2,
  .discover-more-container p {
    width: 100%;
    font-size: 1.3rem;
    max-width: 100%;
  }
  .social-networks-container {
    width: 100%;
  }
  .social-networks-container {
    gap: 0.8rem;
  }
  .button {
    padding: 2rem 1rem;
  }
}
/*# sourceMappingURL=descubre.component.css.map */
