<section>
  <app-navbar />
  <div class="title-container">
    <div>
      <h2>Determine la ruta de la<br> formalización tributaria <br>de su negocio</h2>
      <h3>Puede hacerlo de dos formas</h3>
    </div>
    <img src="/assets/icons/recurso-20.png" alt="imagen" />
  </div>

  <div class="two-options">
    <button (click)="redirectOthepath()" id="boton_ruta_larga"
      aria-label=" Aportar datos económicos <br>básicos del negocio <br>que usted va a simular." role="button">
      Aportar datos económicos <br>
      básicos del negocio <br>
      que usted va a simular.
    </button>
    <button (click)="redirectFormCuatro()" id="boton_ruta_corta"
      aria-label="Usar un modelo <br>que simula su negocio." role="button">
      Usar un modelo,
      <br>que simula su negocio.
    </button>
  </div>
  <div class="warning">
    <img src="/assets/images/warning-img.png" alt="!" />


    <ul role="tree">

      <li role="treeitem">Este simulador no reemplaza el análisis que deba hacer el contribuyente.<br></li>
      <li role="treeitem">Al final del simulardor encontrará el detalle de cómo cumplir con sus obligaciones tributarias. <br></li>
      <li role="treeitem">Oprima
        <b [attr.data-bs-toggle]="'modal'" [attr.data-bs-target]="'#exampleModal-oprima-aqui'">
          aquí
        </b>
        para consultar la lista de negocios para las que este simulador no aplica.<br>
      </li>
      <li role="treeitem"> simulador asume que el titular del negocio es residente a efectos fiscales. Oprima
        <b>
          <a role="button" tabindex="-1" target="_blank"
            [href]="URL">
            aquí
          </a>
        </b> para <br>
        conocer más sobre residencia fiscal.
      </li>
    </ul>



  </div>
</section>


<app-modal [id]="'oprima-aqui'" [modalTitle]="''"
  [modalContent]="'Este simulador no aplica para empresas que sean exportadoras, importadoras, operadores de juegos de suerte y azar, usuarios aduaneros, entidades financieras, prestadores de servicios de telefonía móvil, datos y/o internet y navegación móvil, empresas que contratan con personas o entidades sin residencia o domicilio en Colombia para la prestación de servicios en el territorio nacional.'" />
