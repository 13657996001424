import { Component } from '@angular/core';

@Component({
  selector: 'app-firstnavbar',
  standalone: true,
  imports: [],
  templateUrl: './firstnavbar.component.html',
  styleUrl: './firstnavbar.component.scss'
})
export class FirstnavbarComponent {

}
